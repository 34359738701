import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, of, throwError, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment, } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private UploadAuctionUrl = `${environment.TataapiUri}/api/v1/auction/upload/`;
  private UploadInventoryImageUrl = `${environment.TataapiUri}/api/v1/front_end/upload_inventory_files/`;
  private EditUploadInventoryImageUrl = `${environment.TataapiUri}/api/v1/inventory/edit_inventory_files/`;

  private bidWinnerDocument = `${environment.TataapiUri}/api/v1/auction/bid_settlement_file/ `;

  private UploadVideoUrl = `${environment.TataapiUri}/api/v1/inventory/upload_video/`;
  private vehicleImageUploadUrl = `${environment.TataapiUri}/api/v2/front_end/upload_vehicle_files/`;
  private editVehicleImageUploadUrl = `${environment.TataapiUri}/api/v2/inventory/edit_inventory_vehicle_files/`;
  private vehicleImageUploadUrlAgreement = `${environment.TataapiUri}/api/v1/inventory/agreement/upload_agreement_documents/`;

  constructor(private http: HttpClient, private _Router: Router) { }

  uploadImageDocument(data: FormData) {
    return this.http.post<any>(this.UploadAuctionUrl, data).pipe(catchError(this.handlError))
  }

  EdituploadInventory(data: FormData) {
    return this.http.post<any>(this.EditUploadInventoryImageUrl, data).pipe(catchError(this.handlError))
  }

  uploadInventory(data: FormData) {
    return this.http.post<any>(this.UploadInventoryImageUrl, data).pipe(catchError(this.handlError))
  }

  uploadImage(data: FormData) {
    return this.http.post<any>(this.bidWinnerDocument, data).pipe(catchError(this.handlError))
  }

  UploadVideo(data: FormData) {
    return this.http.post<any>(this.UploadVideoUrl, data).pipe(catchError(this.handlError))
  }
  uploadVehicleImage(Data:any):Observable<any>{
    return this.http.post(this.vehicleImageUploadUrl,Data).pipe(catchError(err => of(err)));
  }
  editUploadVehicleImage(Data:any):Observable<any>{
    return this.http.post(this.editVehicleImageUploadUrl,Data).pipe(catchError(err => of(err)));
  }
  uploadVehicleImageAgreement(Data:any):Observable<any>{
    return this.http.post(this.vehicleImageUploadUrlAgreement,Data).pipe(catchError(err => of(err)));
  }
  handlError(error: HttpErrorResponse) {
    return throwError(error)
  }
}
