import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileUploadService } from 'app/file-upload/file-upload.service';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { CommonService } from 'app/shared/services/MyServices/common.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-agreement',
  templateUrl: './add-agreement.component.html',
  styleUrls: ['./add-agreement.component.scss']
})
export class AddAgreementComponent implements OnInit {

  constructor(public dialog: MatDialogRef<AddAgreementComponent>,private fb: FormBuilder,private commonservices: CommonService, private loader: AppLoaderService,private fileservice :FileUploadService, @Inject(MAT_DIALOG_DATA) public data) { }
  userForm: FormGroup;
  annexure_2Form:FormGroup;
  annexure2Data: any[] = [];
  annexure_1Form:FormGroup;
  annexure1Data: any[] = [];
  uploadedDocuments: { file: File; url: string; position: string }[] = [];
  editingIndex: number | null = null;
  editIndex: number | null = null;
  breakpoint: number;
sub = new Subscription
ImagePositionTypeList = [
    { name: "Vehicle delivery receipt copy", value: "vehicle_delivery_receipt_copy", isSelected: false, position: NaN },
  { name: "Vehicle RC copy", value: "vehicle_rc_copy", isSelected: false, position: NaN },
  { name: "Vehicle Owner ID proof (e.g. Pan Card/ Form 60)", value: "vehicle_owner_id_proof", isSelected: false, position: NaN },
  { name: "Vehicle Owner permanent address proof (e.g. Aadhar Card copy)", value: "vehicle_owner_permanent_address_proof", isSelected: false, position: NaN },
  { name: "Proof of vehicle owner’s current address (if diff. from Permeant address)", value: "vehicle_owner_current_address_proof", isSelected: false, position: NaN },
  { name: "Loan Agreement Copy", value: "loan_agreement_copy", isSelected: false, position: NaN },
  { name: "Motor Vehicle Tax", value: "motor_vehicle_tax", isSelected: false, position: NaN },
  { name: "Insurance Copy", value: "insurance_copy", isSelected: false, position: NaN },
  { name: "Certificate of fitness", value: "certificate_of_fitness", isSelected: false, position: NaN },
  { name: "Vehicle Permit", value: "vehicle_permit", isSelected: false, position: NaN },
  { name: "Signature verification", value: "signature_verification", isSelected: false, position: NaN },

]
  ngOnInit(): void {
    console.log(this.data);
    console.log(this.data.full_name);
    
    this.userForm = this.fb.group({
      full_name: [this.data.flag == 'EDIT' ? this.data.data.full_name : '', Validators.required],
      director_name: [this.data.flag == 'EDIT' ? this.data.data.director_name :'', Validators.required],
      mobile_number: [this.data.flag == 'EDIT' ? this.data.data.mobile_number :'', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      email_id: [this.data.flag == 'EDIT' ? this.data.data.email_id :'', [Validators.required, Validators.email]],
      perment_address: [this.data.flag == 'EDIT' ? this.data.data.perment_address :''],
      current_resident_address: [this.data.flag == 'EDIT' ? this.data.data.current_resident_address :''],
      dealer_name: [this.data.flag == 'EDIT' ? this.data.data.dealer_name :''],
      dealer_code: [this.data.flag == 'EDIT' ? this.data.data.dealer_code :''],
      dealer_position: [this.data.flag == 'EDIT' ? this.data.data.dealer_position :''],
      park_and_sell_period: [this.data.flag == 'EDIT' ? this.data.data.park_and_sell_period :''],
      vehicle_base_price: [this.data.flag == 'EDIT' ? this.data.data.vehicle_base_price :''],
      delaer_ship_service_charges: [this.data.flag == 'EDIT' ? this.data.data.delaer_ship_service_charges :''],
      fixed_commission: [this.data.flag == 'EDIT' ? this.data.data.fixed_commission :''],

      account_name: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].account_name :'', Validators.required],
      bank_name: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].bank_name :'', Validators.required],
      branch_name: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].branch_name :'', Validators.required],
      branch_address: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].branch_address :'', Validators.required],
      branch_city: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].branch_city :''],
      branch_pin_code: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].branch_pin_code :''],
      account_number: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].account_number :''],
      ifsc_code: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].ifsc_code :''],
      branch_code: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].branch_code :''],
      micr_no: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].micr_no :''],
      account_type: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].account_type :''],
      rtgs_transfer: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].rtgs_transfer :''],
      neft_transfer: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].neft_transfer :''],
      // // imgFile:[''],
      // annexure_1: this.fb.array([this.createAnnexure1()]),
      // annexure_2: this.fb.array([this.createAnnexure2()])
    });
    this.annexure_1Form = this.fb.group({
      registration_number: ['', Validators.required],
      chassis_number: ['', Validators.required],
      make: ['', Validators.required],
      model: ['', Validators.required],
      variant: [''],
      km_run: ['', [Validators.required]],
      color: [''],
      insurance_status: [''],
      no_of_tyres: ['', Validators.required],
      engine_no: ['', Validators.required],
      hypothecation_status: ['']
    })
    this.annexure_2Form = this.fb.group({
      registration_number: ['', Validators.required],
      make: ['', Validators.required],
      model: ['', Validators.required],
      km_run: ['', [Validators.required]],
      color: [''],
      job_to_carried: [''],
      est_parts_amt: ['', Validators.required],
      est_labour_amt: ['', Validators.required],
      total_amt: ['', Validators.required]
    })
    if (this.data.flag == 'EDIT') {
      this.annexure1Data=this.data.data.agreement_vehicle_details_annexure1
      this.annexure2Data=this.data.data.agreement_vehicle_details_annexure2
      this.uploadedDocuments=this.data.data.agreement_documents
      this.uploadedDocuments = this.data.data.agreement_documents.map((doc) => ({
        file: null, // No file for existing documents
        url: doc.path, // URL from API
        position: doc.name, // Position from API
      }));
    }
  }
  closeModal(){
    this.dialog.close('cancle');
  }


  // Create Annexure 1 Form Group
  createAnnexure1(): FormGroup {
    return this.fb.group({
      registration_number: ['', Validators.required],
      chassis_number: ['', Validators.required],
      make: ['', Validators.required],
      model: ['', Validators.required],
      variant: [''],
      km_run: ['', [Validators.required]],
      color: [''],
      insurance_status: [''],
      no_of_tyres: ['', Validators.required],
      engine_no: ['', Validators.required],
      hypothecation_status: ['']
    });
  }

  // Create Annexure 2 Form Group
  createAnnexure2(): FormGroup {
    return this.fb.group({
      registration_number: ['', Validators.required],
      make: ['', Validators.required],
      model: ['', Validators.required],
      km_run: ['', [Validators.required]],
      color: [''],
      job_to_carried: [''],
      est_parts_amt: ['', Validators.required],
      est_labour_amt: ['', Validators.required],
      total_amt: ['', Validators.required]
    });
  }

  

  // Submit Form
  onSubmit(): void {
  if (this.uploadedDocuments.length == 0) {
    Swal.fire('Error', 'Please upload  images.', 'error');
    return;
  }

  const hasEmptyPosition = this.uploadedDocuments.some(doc => !doc.position);
  if (hasEmptyPosition) {
    Swal.fire('Error', 'Please assign a position to all images.', 'error');
    return;
  }

  const positions = this.uploadedDocuments.map(doc => doc.position);
  const hasDuplicatePositions = positions.length !== new Set(positions).size;
  if (hasDuplicatePositions) {
    Swal.fire('Error', 'Each image must have a unique position.', 'error');
    return;
  }
  if (this.userForm.valid) {
        console.log(this.userForm.value);
        if(this.data.flag == 'EDIT'){
         this.editAgreement(this.userForm.value);
        }
        else {
        this.submitAgreement(this.userForm.value);
        }
      } else {
        this.userForm.markAllAsTouched();
       Swal.fire('Form is invalid')
      }


   
  }
 submitAgreement(data:any){
  let payload = {
    
    "user_data": {
      "full_name": data.full_name,
      "director_name":data.director_name,
      "mobile_number": data.mobile_number,
      "email_id": data.email_id,
      "perment_address": data.perment_address,
      "current_resident_address": data.current_resident_address,
      "dealer_name": data.dealer_name,
      "dealer_code": data.dealer_code,
      "dealer_position": data.dealer_position,
      "park_and_sell_period":data.park_and_sell_period,
      "vehicle_base_price":data.vehicle_base_price,
      "delaer_ship_service_charges":data.delaer_ship_service_charges,
      "fixed_commission":data.fixed_commission
  },
  "bank_detail": {
    "account_name": data.account_name,
    "bank_name": data.bank_name,
    "branch_name": data.branch_name,
    "branch_address": data.branch_address,
    "branch_city": data.branch_city,
    "branch_pin_code": data.branch_pin_code,
    "account_number": data.account_number,
    "ifsc_code": data.ifsc_code,
    "branch_code":data.branch_code,
    "micr_no":data.micr_no,
    "account_type":data.account_type,
    "rtgs_transfer":data.rtgs_transfer,
    "neft_transfer":data.neft_transfer
},
// "annexure_1":data.annexure_1,
// "annexure_2":data.annexure_2
"annexure_1": this.annexure1Data,
"annexure_2":this.annexure2Data
  }
  // if(this.data.flag == 'EDIT'){
  //   payload['agreement_code'] = this.data.data.agreement_code
  // }
  this.loader.open();
  this.sub.add(this.commonservices.addagreement(payload).subscribe({
    next: (res: any) => {
      if (res.success) {
        this.uploadImages(res.agreement_code)
        console.log(res);
        this.loader.close();
      }
      else{
        Swal.fire(res.error.data.msg)
        this.loader.close();
      }
    },
    error: (err) => {
      Swal.fire('Oops...', err.error.data.msg, 'error')
      this.loader.close();
    },
    complete: () => { }
  }))
 }
 editAgreement(data:any){
  let payload = {
    
    "user_data": {
      "full_name": data.full_name,
      "director_name":data.director_name,
      "mobile_number": data.mobile_number,
      "email_id": data.email_id,
      "perment_address": data.perment_address,
      "current_resident_address": data.current_resident_address,
      "dealer_name": data.dealer_name,
      "dealer_code": data.dealer_code,
      "dealer_position": data.dealer_position,
      "park_and_sell_period":data.park_and_sell_period,
      "vehicle_base_price":data.vehicle_base_price,
      "delaer_ship_service_charges":data.delaer_ship_service_charges,
      "fixed_commission":data.fixed_commission
  },
  "bank_detail": {
    "account_name": data.account_name,
    "bank_name": data.bank_name,
    "branch_name": data.branch_name,
    "branch_address": data.branch_address,
    "branch_city": data.branch_city,
    "branch_pin_code": data.branch_pin_code,
    "account_number": data.account_number,
    "ifsc_code": data.ifsc_code,
    "branch_code":data.branch_code,
    "micr_no":data.micr_no,
    "account_type":data.account_type,
    "rtgs_transfer":data.rtgs_transfer,
    "neft_transfer":data.neft_transfer
},
// "annexure_1":data.annexure_1,
// "annexure_2":data.annexure_2
"annexure_1": this.annexure1Data,
"annexure_2":this.annexure2Data
  }
  if(this.data.flag == 'EDIT'){
    payload['agreement_code'] = this.data.data.agreement_code
  }
  this.loader.open();
  this.sub.add(this.commonservices.editagreement(payload).subscribe({
    next: (res: any) => {
      if (res.success) {
        this.uploadImages(res.agreement_code)
        console.log(res);
        this.loader.close();
      }
      else{
        Swal.fire(res.error.data.msg)
        this.loader.close();
      }
    },
    error: (err) => {
      Swal.fire('Oops...', err.error.data.msg, 'error')
      this.loader.close();
    },
    complete: () => { }
  }))
 }
 //document function
 onFileSelect(event: Event): void {
  const input = event.target as HTMLInputElement;
  if (!input.files || input.files.length === 0) {
    return; // No files selected
  }

  Array.from(input.files).forEach((file) => {
    const reader = new FileReader();
    reader.onload = () => {
      this.uploadedDocuments.push({
        file,
        url: reader.result as string,
        position: '', // Default position value
      });
    };
    reader.readAsDataURL(file);
  });

  // Reset the file input to allow re-upload of the same files
  input.value = '';
}

updatePosition(index: number, positionValue: string): void {
  if (index >= 0 && index < this.uploadedDocuments.length) {
    this.uploadedDocuments[index].position = positionValue;
  } else {
    console.error('Invalid index provided to updatePosition');
  }
}


removeDocument(index: number): void {
  if (index >= 0 && index < this.uploadedDocuments.length) {
    this.uploadedDocuments.splice(index, 1);
  } else {
    console.error('Invalid index provided to removeDocument');
  }
}
uploadImages(code:any) {
  this.loader.open();
  const formData = new FormData();
  
  
  formData.append("agreement_code", code);
formData.append("contract_type", 'agreement');
if (this.data.flag == 'EDIT') {
  formData.append("action_type", 'update');
}
  // this.uploadedDocuments.forEach((doc: any) => {
  //   formData.append(doc.position, doc.file, doc.file.name);
  // });
  this.uploadedDocuments.forEach((doc) => {
    if (doc.file) {
      // For newly uploaded files
      formData.append(doc.position || 'new_document', doc.file, doc.file.name);
    } else {
      // For existing documents, create a Blob or placeholder to pass in FormData
      const existingFileBlob = new Blob([], { type: 'application/octet-stream' });
      formData.append(doc.position, existingFileBlob, doc.url.split('/').pop() || 'existing_file');
    }
  });

  // this.loader.open();

  this.sub.add(this.fileservice.uploadVehicleImageAgreement(formData).subscribe({
    next: (res: any) => {
      if (res.success) {
        console.log(res);
        
        Swal.fire(res.data.msg)
        this.dialog.close('saved');
  this.loader.close();
      } else {
        this.loader.close();
        Swal.fire(res.error.data.msg)
        this.dialog.close('cancle');
  this.loader.close();
      }
      
    },
    error: (err) => {
      Swal.fire('Oops...', err.error.data.msg, 'error');
      this.loader.close();
    },
    complete: () => {}
  }));
}
duplicateImgPosValidation(index: number): void {
  const selectedPosition = this.uploadedDocuments[index].position;
  // Check for duplicates but exclude the current image (index)
  const isDuplicate = this.uploadedDocuments.some(
    (doc, i) => doc.position === selectedPosition && i !== index && selectedPosition !== null
  );

  if (isDuplicate) {
    // Show alert using SweetAlert2
    Swal.fire('Duplicate position not allowed');
    this.uploadedDocuments[index].position = null; // Only reset if it's a duplicate
    return;
  }
}

addOrUpdateAnnexure2() {
  if (this.annexure_2Form.invalid) {
    this.annexure_2Form.markAllAsTouched();
    return;
  }

  const formData = this.annexure_2Form.value;
  formData.km_run = Number(formData.km_run) || ''; 
formData.est_parts_amt = Number(formData.est_parts_amt) || '';
formData.est_labour_amt = Number(formData.est_labour_amt) || '';
formData.total_amt = Number(formData.total_amt) || '';


  if (this.editingIndex !== null) {
    // Update existing entry
    this.annexure2Data[this.editingIndex] = formData;
    this.editingIndex = null;
  } else {
    // Add new entry
    this.annexure2Data.push(formData);
  }

  this.annexure_2Form.reset();
}

editAnnexure2(index: number) {
  this.editingIndex = index;
  this.annexure_2Form.patchValue(this.annexure2Data[index]);
}

removeAnnexure2(index: number) {
  this.annexure2Data.splice(index, 1);
}

addOrUpdateAnnexure1(): void {


  if (this.annexure_1Form.invalid) {
    this.annexure_1Form.markAllAsTouched();
    return;
  }

  const formData = this.annexure_1Form.value;
  formData.km_run = Number(formData.km_run) || '';
  formData.no_of_tyres = Number(formData.no_of_tyres) || '';


  if (this.editIndex !== null) {
    // Update existing entry
    this.annexure1Data[this.editIndex] = formData;
    this.editIndex = null;
  } else {
    // Add new entry
    this.annexure1Data.push(formData);
  }

  this.annexure_1Form.reset();
  // if (this.annexure_1Form.valid) {
  //   const formData = this.annexure_1Form.value;

  //   if (this.editIndex === null) {
  //     // Add new entry
  //     formData.km_run = Number(formData.km_run) || '';
  //     formData.no_of_tyres = Number(formData.no_of_tyres) || '';
  //     this.annexure1Data.push(formData);
  //   } else {
  //     this.annexure1Data[this.editIndex] = formData
  //     this.editIndex = null; 
  //   }
  //   this.annexure_1Form.reset();
  // } else {
  //   this.annexure_1Form.markAllAsTouched(); 
  // }
}

// Edit Annexure
editAnnexure1(index: number): void {
  this.editIndex = index; 
  const annexure = this.annexure1Data[index];
  this.annexure_1Form.patchValue(annexure); 
}

// Remove Annexure
removeAnnexure1(index: number): void {
  this.annexure1Data.splice(index, 1); 
}
}
