import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, FormControl, FormArray } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { SubCategoryDetails, InventoryMaster, FormArrayForFile, ControlDetail, ElementData, ControlBind } from "../../../shared/models/models";

import { AppConfirmService } from "../../../shared/services/app-confirm/app-confirm.service";
import { AppLoaderService } from "../../../shared/services/app-loader/app-loader.service";
import { egretAnimations } from "../../../shared/animations/egret-animations";
import Swal from "sweetalert2";
import { InventoryService } from "../../../shared/services/MyServices/inventory.service";
import { AuthorizeService } from "../../../shared/services/MyServices/authorize.service";
import { CommonService } from "../../../shared/services/MyServices/common.service";
import { TataServiceService } from "../../../TataService/tata-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { first } from "rxjs/operators";
import { AppDateAdapter, APP_DATE_FORMATS } from "../../../shared/directives/date.adapter";
import { DateAdapter, MatStepper, MAT_DATE_FORMATS, } from "@angular/material";
import { NgxImageCompressService } from "ngx-image-compress";
import { FileUploadService } from "app/file-upload/file-upload.service";
import { VehicleData, ApproveData } from "app/shared/models/models";
import { DatePipe } from "@angular/common";
import { DataPassService } from "../../../shared/services/MyServices/data-pass.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-inventory-add",
  templateUrl: "./inventory-add.component.html",
  styleUrls: ["./inventory-add.component.scss"],
  animations: egretAnimations,

  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
  ],
})
export class InventoryAddComponent implements OnInit {

  txtchassisNo: any;
  base64Image: any;
  approved_status: any;
  EReport: any;
  MapId: any;
  ShowUpdate: boolean = false;
  chassisInput: boolean = true;
  SelectElementData = [];
  SelectImages = [];
  Data: VehicleData[];
  AttachementFile: string;
  InventoryID: string;
  InventoryStatus: string;
  vc_number: string;
  model_id: string;
  GetData: any;
  GetStockData: any;
  public hasBaseDropZoneOver: boolean = false;
  console = console;

  public showForm: boolean = false;

  BasicFormGroup: FormGroup;
  TechnicalFormGroup: FormGroup;
  OtherFormGroup: FormGroup;

  InventoryForm: FormGroup;
  InventoryUploads: FormArray;
  imageSrc: string;
  AllImageFile = [];
  AllVechileImages: any;
  DocumentFile: any;
  testFile: any;
  CoverImageFile: any;

  cat_id: any;
  sub_cat_id: any;
  sub_cat_detail_id: any;

  imageSrcUrls = [];

  //basic Array
  VechileInfoArray = [];
  DealerDetailsArray = [];
  AddressArray = [];

  //Technical Array
  VehicleInteriorDetailsArray = [];
  VehicleDetailsArray = [];
  MakeModelDetailsArray = [];
  RegistrationDetailsArray = [];

  //Other Array
  InsuranceOtherDetailsArray = [];
  ImagesReportsArray = [];
  OtherDetailsArray = [];

  control_detail_id = [];
  BasicDetailsArray = [];
  TechnicalDetailsArray = [];
  OtherArray = [];

  LhsImageArray = [];
  RhsImageArray = [];
  InsideImageArray = [];
  CoverImageArray = [];
  FrontImageArray = [];
  BackImageArray = [];
  AllTyreImageArray = [];
  dataFile = [];
  ControlMenuArray: any[];
  SelectData = [];
  InventoryArray: any[];
  categoryArray: any[];
  SubcategoryArray: any[];
  CategoryId: any;
  SubCategoryId: any;
  ElementArray: any[];

  StateArray: any[];
  DistictArray: any[];
  CityArray: any[];
  TalukaArray: any[];
  PincodeArray: any[];
  stateCode: string;
  stateName: string;
  districtName: string;
  cityName: string;
  talukaName: string;
  postalCode: string;
  DealerDetails: any;
  CheckedLabel: any;
  is_active: boolean = true;
  positionElementValue = [];
  defaultValue = 'Maharashtra';
  partyType: string;
  ImagePositionTypeList = [];

  editable: boolean = true;
  userData: any;
  dealerCode: any;
  isDealerVehicle: boolean = false
  isPageUrlCheck: string;
  readable: any;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private datepipe: DatePipe,
    private confirmService: AppConfirmService, private loader: AppLoaderService, private commonService: CommonService,
    private imageCompress: NgxImageCompressService, private FileUpService: FileUploadService, private _sanitizer: DomSanitizer,
    private TataService: TataServiceService, private DataPass: DataPassService, private InvenService: InventoryService) {
    this.ImagePositionTypeList = [
      { name: "Cover Image", value: "cover_image", isSelected: false, position: NaN },
      { name: "Front", value: "front", isSelected: false, position: NaN },
      { name: "Back", value: "back", isSelected: false, position: NaN },
      { name: "LHS", value: "lhs", isSelected: false, position: NaN },
      { name: "RHS", value: "rhs", isSelected: false, position: NaN },
      { name: "Cabin", value: "inside_cabin", isSelected: false, position: NaN },
      { name: "Speedometer", value: "speedometer", isSelected: false, position: NaN },
      { name: "Chassis", value: "chassis", isSelected: false, position: NaN },
      { name: "Front right tyre", value: "front_right_tyre", isSelected: false, position: NaN },
      { name: "Front left tyre", value: "front_left_tyre", isSelected: false, position: NaN },
      { name: "Rear right tyre", value: "rear_right_tyre", isSelected: false, position: NaN },
      { name: "Rear left tyre", value: "rear_left_tyre", isSelected: false, position: NaN },
      { name: "Tyre 5", value: "tyre_5", isSelected: false, position: NaN },
      { name: "Tyre 6", value: "tyre_6", isSelected: false, position: NaN },
      { name: "Tyre 7", value: "tyre_7", isSelected: false, position: NaN },
      { name: "Tyre 8", value: "tyre_8", isSelected: false, position: NaN },
      { name: "Tyre 9", value: "tyre_9", isSelected: false, position: NaN },
      { name: "Tyre 10", value: "tyre_10", isSelected: false, position: NaN },
      { name: "Tyre 11", value: "tyre_11", isSelected: false, position: NaN },
      { name: "Tyre 12", value: "tyre_12", isSelected: false, position: NaN },
      { name: "Spare Tyre", value: "spare", isSelected: false, position: NaN },
    ]
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
    alert(e);
  }

  disableinput: string;
  approval_status:boolean;
  fromstock:boolean;
  @ViewChild(MatStepper, { static: false }) stepper: MatStepper;
  validatePrice:boolean = false;

  ngOnInit() {
    var list = JSON.parse(localStorage.getItem('PageDetails'))
    for (let entry of list) {
      if (entry.page_detail.length > 0) {
        for (let entryDetail of entry.page_detail) {
          if (entryDetail.page_url == 'StockVehcile') {
            this.readable= entryDetail.readable
          }
        }
      }
    }
    this.userData = JSON.parse(localStorage.getItem('loginData'))
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);
    this.CheckedLabel = "Dealer only";
    this.labelToggal = "My Stock"
    this.BasicDetailsArray = [];
    this.TechnicalDetailsArray = [];
    this.OtherArray = [];
    this.urls = [];
    this.checkDealer = ""
    //this.InventoryID = this.route.snapshot.params["InventoryId"];
    this.route.queryParams.subscribe(params => {
      this.checkDealer = params["isDealer"]
      this.approval_status=params["approval_status"] == 'true' ? true: false;
      console.log(this.approval_status);
    })
    this.route.queryParams.subscribe(params => {
      console.log(params);
      const key1 = params['fromstock'];
     this.fromstock=params['fromstock'] == 'true' ? true : false;
      console.log("from stock",this.fromstock);
    });
    this.InventoryID = localStorage.getItem("InventoryId");
    this.disableinput = localStorage.getItem("InventoryStatus");
    this.partyType = localStorage.getItem('partyTypeName')
    if (this.InventoryID) {
      this.ShowUpdate = true;
      this.chassisInput = false;
      this.GetData = this.DataPass.getOption();
      //this.GetData = localStorage.getItem('InventoryDetail')
      if (this.userData.distributor_code == this.GetData.dealer_code) {
        this.isDealerVehicle = true
      }
      this.BindItem(this.GetData);
    } else {
      localStorage.removeItem("InventoryStatus");
      this.isDealerVehicle = true
      this.BindNewItem();
    }
    localStorage.removeItem("InventoryId");
    localStorage.removeItem("approved_status");
    this.vc_number = this.route.snapshot.params["vc_number"];
    this.vc_number = localStorage.getItem("vc_number");
    if (this.vc_number) {
      this.GetStockData = this.DataPass.getStockData();
      this.lobName = this.GetStockData.lob;
      this.pplName = this.GetStockData.ppl;
    }
    if ((this.InventoryID == undefined || this.InventoryID == '') && this.GetStockData == undefined) {
      this.router.navigate(['/pages/StockVehcile'])
    }
    this.DealerDetails = JSON.parse(localStorage.getItem('dealerDetails'))
    localStorage.removeItem("vc_number");
    localStorage.removeItem("model_id");

  }
  save() { }

  // login_() {
  //   var json = {
  //     username: "BRAI1003550",
  //     password: "SBRAI1003550",
  //     dev_id: "897jhjh6",
  //     app_version: "1.0",
  //     app_name: "com.tatamotors.tataoksourcing",
  //   };
  //   this.AuthService.TataloginAuth(json).subscribe(
  //     (data) => {
  //       if (data.data.length > 0) {
  //         this.setTataSession(data);
  //       } else {

  //       }
  //     },
  //     (err) => {
  //       console.log(err);
  //       Swal.fire(err.error.msg);
  //     }
  //   );
  // }
  setTataSession(data) {
    localStorage.setItem("TataToken", data.token.access_token);
  }

  BindNewItem() {
    this.InventoryForm = this.fb.group({
      InventoryType: [""],
      category: [""],
      Subcategory: [""],
      InventoryUploadData: this.fb.array([]),
    });

    this.BindInventory();
  }

  BindItem(Items) {
    if (Items) {
      this.cat_id = Items.catid;
      this.sub_cat_id = Items.sub_cat_id;
      this.sub_cat_detail_id = Items.sub_cat_detail_id;
      this.lobName = Items.sub_cat_name;
      this.pplName = Items.sub_cat_detail_name;
      this.approved_status = localStorage.getItem("approved_status");
      this.dealerCode = Items.dealer_code;

      localStorage.removeItem("InventoryId");
      var detailsArray = Items.control_detail

      for (let i = 0; i < detailsArray.length; i++) {
        if (detailsArray[i].element_detail_value == "Vehicle Certificate Type") {
          this.vehicle_certificate_type = detailsArray[i].control_detail_value
        }
      }
    }
    this.InventoryForm = this.fb.group({
      InventoryType: [Items.catid || ""],
      category: [Items.sub_cat_id || ""],
      Subcategory: [Items.sub_cat_detail_id || "",
      ],
      InventoryUploadData: this.fb.array([]),
    });

    this.BindInventory();
  }

  chassisinput: string;

  EditInventoryData(InventoryID) {

    const objData: ApproveData = {} as ApproveData;
    objData.id = InventoryID;
    this.InvenService.ViewInventoryById(objData).subscribe((data) => {
      if (data instanceof HttpErrorResponse) {
        return;
      }
      if (data.success) {

        this.BindItem(data.data);

      } else {
        Swal.fire(data.data.msg, "Error");
      }
    });
  }

  BindInventory() {
    this.commonService.BindInventory().subscribe(
      (res) => {
        if (res.success == true) {
          this.InventoryArray = res.data;
          if (this.cat_id != undefined) {
            var selectData = this.InventoryArray.filter(
              (data) => data.cat_id === this.cat_id
            );
            this.InventoryForm.controls["InventoryType"].setValue(
              selectData[0]
            );
            this.onSelectInventory(selectData[0]);
          } else {
            this.InventoryForm.controls["InventoryType"].setValue(
              this.InventoryArray[0]
            );
            this.onSelectInventory(this.InventoryArray[0]);
          }
          if (this.disableinput === "SOLD") {
            this.InventoryForm.disable();
            localStorage.removeItem("InventoryStatus");
          }
        } else {
          Swal.fire("Oops...", res.msg, "error");
        }
      },
      (err) => {
        Swal.fire("Oops...", err.msg, "error");
      }
    );
  }

  onSelectInventory(value) {
    this.cat_id = "";
    const dataList: SubCategoryDetails = {} as SubCategoryDetails;
    dataList.cat_id = value.cat_id;
    dataList.lob = this.lobName;
    this.cat_id = value.cat_id;
    this.commonService.BindCategory(dataList).subscribe(
      (res) => {
        if (res.success == true) {
          this.categoryArray = res.data;
          if (this.sub_cat_id != undefined) {
            var selectData = this.categoryArray.filter(
              (data) => data.sub_cat_id === this.sub_cat_id
            );
            this.InventoryForm.controls["category"].setValue(selectData[0]);
            this.onSelectcategory(selectData[0]);
          } else {

            let subcatname = this.GetStockData.lob;

            var selectSubcatData = this.categoryArray.filter((data) => data.sub_cat_name == subcatname);
            this.InventoryForm.controls["category"].setValue(selectSubcatData[0]);
            this.onSelectcategory(selectSubcatData[0]);
          }
        } else {
          Swal.fire(res.msg);
        }
      },
      (err) => {
        Swal.fire("Oops...", err.error.data.msg, "error");
      }
    );
  }

  FormCategory(id): SubCategoryDetails {
    const data: any = {} as any;
    data.cat_id = id;
    return data;
  }

  onSelectcategory(cateval) {

    this.sub_cat_id = "";
    const dataList: SubCategoryDetails = {} as SubCategoryDetails;
    dataList.subcat_id = cateval.sub_cat_id;
    dataList.ppl = this.pplName;
    this.sub_cat_id = cateval.sub_cat_id;
    this.commonService.BindSubCategory(dataList).subscribe(
      (data) => {
        if (data.success == true) {
          this.SubcategoryArray = data.data;
          if (this.sub_cat_detail_id != undefined) {
            var selectData = this.SubcategoryArray.filter(
              (data) => data.sub_cat_detail_id === this.sub_cat_detail_id
            );
            this.InventoryForm.controls["Subcategory"].setValue(selectData[0]);
            this.onSelectSubcategory(selectData[0]);
          } else {
            let subcatname = this.GetStockData.ppl;
            subcatname = subcatname.toLowerCase().replace(/\b[a-z]/g, function (letter) {
              return letter.toUpperCase();
            });
            var selectsubcatData = this.SubcategoryArray.filter(
              (data) => data.name.toUpperCase() == subcatname.toUpperCase()
            );
            this.InventoryForm.controls["Subcategory"].setValue(
              selectsubcatData[0]

            );
            this.onSelectSubcategory(selectsubcatData[0]);
          }
        } else {
          Swal.fire(data.msg);
        }
      },
      (err) => {
        Swal.fire(err.error.data.msg);
      }
    );
  }
  editableinput: any;
  editinputDisabled: boolean = false;
  onSelectSubcategory(Subcateval) {
    this.VechileInfoArray = [];
    this.DealerDetailsArray = [];
    this.AddressArray = [];
    this.VehicleInteriorDetailsArray = [];
    this.VehicleDetailsArray = [];
    this.MakeModelDetailsArray = [];
    this.RegistrationDetailsArray = [];
    this.InsuranceOtherDetailsArray = [];
    this.ImagesReportsArray = [];
    this.OtherDetailsArray = [];

    this.ControlMenuArray = [];
    this.BasicDetailsArray = [];
    this.TechnicalDetailsArray = [];
    this.OtherArray = [];

    const ControlList: ControlBind = {} as ControlBind;
    ControlList.catId = this.cat_id;
    ControlList.subcat_id = this.sub_cat_id;
    ControlList.subcat_detail_id = Subcateval.sub_cat_detail_id;

    this.commonService.BindControls(ControlList).subscribe(
      (data) => {
        this.ControlMenuArray = [];
        if (data.success == true) {
          this.ControlMenuArray = data.data;
          for (let i = 0; i < this.ControlMenuArray.length; i++) {
            let control_name = this.ControlMenuArray[i]["control_name"];
            let mapping_id = this.ControlMenuArray[i]["mapping_id"];
            let ControlType = this.ControlMenuArray[i]["control_type"];
            let element_id = this.ControlMenuArray[i]["element_id"];
            let element_name = this.ControlMenuArray[i]["element_name"];
            let group = this.ControlMenuArray[i]["group"];
            let SubGroup = this.ControlMenuArray[i]["sub_group"];
            if (group == "Basic Details") {
              if (SubGroup == "Vehicle info") {
                this.VechileInfoArray.push(this.ControlMenuArray[i]);
              }
              if (SubGroup == "Dealer Details") {
                this.DealerDetailsArray.push(this.ControlMenuArray[i]);
              }
              if (SubGroup == "Address") {
                if (element_name != "Varient") {
                  this.AddressArray.push(this.ControlMenuArray[i]);
                }
                // this.AddressArray.push(this.ControlMenuArray[i]);
              }
            }

            if (group == "Technical Details") {
              if (SubGroup == "Vehicle Interior Details") {
                // if(element_name != "Gear Box" ){
                //   this.VehicleInteriorDetailsArray.push(this.ControlMenuArray[i]);
                // }
                //  if(element_name != "Climate Control" ) {
                //   this.VehicleInteriorDetailsArray.push(this.ControlMenuArray[i]);
                // } 
                // if(element_name != "Power Steering" ){
                //   this.VehicleInteriorDetailsArray.push(this.ControlMenuArray[i]);
                // }
                if (element_name == "Gear Box" || element_name == "Climate Control" || element_name == "Power Steering") {

                }
                else {
                  this.VehicleInteriorDetailsArray.push(this.ControlMenuArray[i]);
                }
                // \|| element_name != "Climate Control" || element_name != "Power Steering" 
                // console.log(this.VehicleInteriorDetailsArray)
              }
              if (SubGroup == "Vehicle Details") {
                this.VehicleDetailsArray.push(this.ControlMenuArray[i]);
              }
              if (SubGroup == "Make, Model Details") {
                if (element_name != "Engine No") {
                  this.MakeModelDetailsArray.push(this.ControlMenuArray[i]);
                }
              }
              if (SubGroup == "Registration Details") {
                this.RegistrationDetailsArray.push(this.ControlMenuArray[i]);
              }
            }

            if (group == "Other") {
              if (SubGroup == "Insurance & other Details") {
                this.InsuranceOtherDetailsArray.push(this.ControlMenuArray[i]);
              }
              if (SubGroup == "Images & Reports") {
                if (element_name != "Remarks") {
                  this.ImagesReportsArray.push(this.ControlMenuArray[i]);
                }
                // this.ImagesReportsArray.push(this.ControlMenuArray[i]);
              }
              if (SubGroup == "Other Details") {
                this.OtherDetailsArray.push(this.ControlMenuArray[i]);
              }
            }

            //Bind State
            if (control_name == "DrpState") {

              this.BindState();
            }
            //Bind State

            var selectedValue = "";
            if (this.InventoryID) {
              if (element_name == "Evaluation Report") {
                if (this.InventoryID) {
                  try {
                    if (this.GetData.inventory_documents.documents.length > 0) {
                      this.SelectImages = this.GetData.inventory_documents.documents;
                      if (this.SelectImages.length > 0) {
                        this.EReport = this.SelectImages[0].path;
                      }
                    }
                  } catch (ex) { }
                }
              } else if (element_name == "Vehicle Image") {
                if (this.InventoryID) {
                  try {
                    if (this.GetData.inventory_documents.images.length > 0) {
                      this.SelectImages = this.GetData.inventory_documents.images;
                      this.EditBindControlArray(this.SelectImages);
                    }
                  } catch (ex) { }
                }
              } else if (element_name == "Cover Image") {
              } else {
                if (this.InventoryID) {
                  try {
                    var SelectElementData = this.GetData.control_detail.find(
                      (x) => x.element_detail_value === element_name
                    );
                    selectedValue = SelectElementData.control_detail_value;
                  } catch (ex) { }
                }
              }
            }
            if (element_name == "Visibility") {
              if (this.InventoryID) {
                try {
                  var SelectElementData = this.GetData.control_detail.find(
                    (x) => x.element_detail_value === element_name
                  );
                  selectedValue = SelectElementData.control_detail_value;
                  this.CheckedLabel = selectedValue;
                  if (this.CheckedLabel == "Dealer only") {
                    this.is_active = true;
                    this.labelToggal = "My Stock"
                  } else {
                    this.is_active = false;
                    this.labelToggal = "Regional Stock"
                  }
                } catch (ex) { }
              }
            }

            if (selectedValue == "" || selectedValue == null) {
              if (this.ControlMenuArray[i]["ismandatory"] == true) {
                if (
                  ControlType == "GeoDropdown" ||
                  ControlType == "Dropdown" ||
                  ControlType == "Checkbox" ||
                  ControlType == "RadioButton" ||
                  ControlType == "FileUpload" ||
                  ControlType == "MultiFileUpload" ||
                  ControlType == "Toggle Button"
                ) {
                  if (ControlType == "Toggle Button") {
                    this.InventoryForm.addControl(mapping_id, new FormControl(""));
                  }
                  else {
                    this.InventoryForm.addControl(mapping_id, new FormControl(""));
                  }

                } else {

                  this.InventoryForm.addControl(
                    mapping_id, new FormControl("")
                  );
                }
              } else {
                this.InventoryForm.addControl(mapping_id, new FormControl());
              }
            } else {
              if (this.ControlMenuArray[i]["ismandatory"] == true) {
                if (ControlType == "GeoDropdown") {
                  if (element_name == "State") {
                    this.MapId = mapping_id;
                    this.stateName = selectedValue;
                    this.BindState();
                  } else if (element_name == "District") {
                    this.districtName = selectedValue;
                    this.InventoryForm.addControl(mapping_id, new FormControl(selectedValue));
                  } else if (element_name == "City") {
                    this.cityName = selectedValue;
                    this.InventoryForm.addControl(mapping_id, new FormControl(selectedValue));
                  } else if (element_name == "Taluka") {
                    this.talukaName = selectedValue;
                    this.InventoryForm.addControl(mapping_id, new FormControl(selectedValue));
                  } else {
                    this.InventoryForm.addControl(mapping_id, new FormControl(selectedValue));
                  }
                } else if (
                  ControlType == "Dropdown" || ControlType == "Checkbox" || ControlType == "RadioButton" ||
                  ControlType == "FileUpload" || ControlType == "MultiFileUpload" || ControlType == "Toggle Button") {
                  if (ControlType == "Toggle Button") {
                    this.InventoryForm.addControl(mapping_id, new FormControl(selectedValue));
                  } else {
                    this.InventoryForm.addControl(mapping_id, new FormControl(selectedValue));
                  }
                } else {
                  this.InventoryForm.addControl(
                    mapping_id,
                    new FormControl(selectedValue)
                  );
                }
              } else {
                if (ControlType == "GeoDropdown") {
                  if (element_name == "State") {
                    this.MapId = mapping_id;
                    this.stateName = selectedValue;
                    this.BindState();
                    this.InventoryForm.addControl(
                      mapping_id,
                      new FormControl()
                    );
                  } else if (element_name == "District") {
                    this.districtName = selectedValue;
                    this.InventoryForm.addControl(
                      mapping_id,
                      new FormControl(selectedValue)
                    );
                  } else if (element_name == "City") {
                    this.cityName = selectedValue;
                    this.InventoryForm.addControl(
                      mapping_id,
                      new FormControl(selectedValue)
                    );
                  } else if (element_name == "Taluka") {
                    this.talukaName = selectedValue;
                    this.InventoryForm.addControl(
                      mapping_id,
                      new FormControl(selectedValue)
                    );
                  } else {
                    this.InventoryForm.addControl(
                      mapping_id,
                      new FormControl(selectedValue)
                    );
                  }
                } else {
                  this.InventoryForm.addControl(
                    mapping_id,
                    new FormControl(selectedValue)
                  );
                }
              }
            }
          }

          // Basic Details
          if (this.VechileInfoArray.length > 0) {
            this.BasicDetailsArray.push(
              this.FormAccodinData("Vehicle Info", this.VechileInfoArray)
            );
          }
          if (this.DealerDetailsArray.length > 0) {
            this.BasicDetailsArray.push(this.FormAccodinData("Dealer Details", this.DealerDetailsArray));
          }
          if (this.AddressArray.length > 0) {
            this.BasicDetailsArray.push(
              this.FormAccodinData("Address", this.AddressArray)
            );
          }
          if (this.OtherDetailsArray.length > 0) {
            this.BasicDetailsArray.push(this.FormAccodinData("Other Details", this.OtherDetailsArray));
          }

          // Techincaldata
          if (this.MakeModelDetailsArray.length > 0) {
            this.TechnicalDetailsArray.push(
              this.FormAccodinData(
                "Make, Model Details",
                this.MakeModelDetailsArray
              )
            );
          }
          if (this.VehicleInteriorDetailsArray.length > 0) {
            this.TechnicalDetailsArray.push(
              this.FormAccodinData(
                "Vehicle Interior Details",
                this.VehicleInteriorDetailsArray
              )
            );
          }
          if (this.VehicleDetailsArray.length > 0) {
            this.TechnicalDetailsArray.push(
              this.FormAccodinData("Vehicle Details", this.VehicleDetailsArray)
            );
          }
          if (this.RegistrationDetailsArray.length > 0) {
            this.TechnicalDetailsArray.push(
              this.FormAccodinData(
                "Registration Details",
                this.RegistrationDetailsArray
              )
            );
          }
          if (this.InsuranceOtherDetailsArray.length > 0) {
            this.TechnicalDetailsArray.push(this.FormAccodinData("Insurance & other Details", this.InsuranceOtherDetailsArray));
          }
          // Other Details
          // if (this.InsuranceOtherDetailsArray.length > 0) {
          //   this.OtherArray.push(this.FormAccodinData("Insurance & other Details", this.InsuranceOtherDetailsArray));
          // }
          // if (this.OtherDetailsArray.length > 0) {
          //   this.OtherArray.push(this.FormAccodinData("Other Details", this.OtherDetailsArray));
          // }
          if (this.ImagesReportsArray.length > 0) {

            this.OtherArray.push(this.FormAccodinData("Images & Video", this.ImagesReportsArray));
          }

          if (this.GetStockData) {
            this.txtchassisNo = this.GetStockData.chassis_number.chassis_no;
            this.onSearchChange(this.txtchassisNo);
          }
        } else {
          // this.showForm = false;
          Swal.fire(data.msg);
        }
      },
      (err) => {
        this.ControlMenuArray = [];
        // this.showForm = false;
        Swal.fire(err.error.data.msg);
      }
    );
  }
  BindState() {
    this.TataService.BindState("").pipe(first()).subscribe((res) => {
      if (res instanceof HttpErrorResponse) {
        return;
      }
      if (res.length > 0) {
        this.StateArray = res;

        if (this.stateName != undefined) {
          if (this.InventoryID) {
            var selectData = this.StateArray.filter(
              (data) => data.name === this.stateName
            );
            this.InventoryForm.controls[this.MapId].setValue(this.stateName);
            this.onSelectState(selectData[0]);
          }
        }
        this.DistictArray = [];
        this.CityArray = [];
        this.TalukaArray = [];
        this.PincodeArray = [];
      } else {
        const errorMessage = res;
        return;
      }
    },
      (error) => {
        console.log(error);
      }
    );
  }

  onSelectState(value) {
    this.stateCode = value.code;
    this.stateName = value.name;
    let json = '{"state":{"state":"' + this.stateName + '","code":"' + this.stateCode + '"}}';
    // this.TataService.BindDistrict(json).pipe(first()).subscribe(
    //     (res) => {
    //       if (res instanceof HttpErrorResponse) {
    //         return;
    //       }
    //       if (res.length > 0) {
    //         this.DistictArray = res;
    //         if (this.districtName != undefined) {
    //           this.onSelectDistrict(this.districtName);
    //         }
    //         this.CityArray = [];
    //         this.TalukaArray = [];
    //         this.PincodeArray = [];
    //       } else {
    //         const errorMessage = res.msg;
    //         console.log(errorMessage);
    //         return;
    //       }
    //     },
    //     (error) => {
    //       console.log(error);
    //     }
    //   );
  }

  FormAccodinData(Title, Content: any[]): AccodinData {
    const Data: AccodinData = {} as AccodinData;
    Data.header = Title;
    Data.content = Content;
    return Data;
  }

  onSelectDistrict(value) {
    this.districtName = value;
    let json = '{"state":{"state":"' + this.stateName + '","code":"' + this.stateCode + '"},"district":"' + this.districtName + '"}';
    this.TataService.BindCity(json).pipe(first()).subscribe((res) => {
      if (res instanceof HttpErrorResponse) {
        return;
      }
      if (res.length > 0) {
        this.CityArray = res;
        if (this.cityName != undefined) {
          this.onSelectCity(this.cityName);
        }
        this.TalukaArray = [];
        this.PincodeArray = [];
      } else {
        const errorMessage = res.msg;
        console.log(errorMessage);
        return;
      }
    },
      (error) => {
        console.log(error);
      }
    );
  }

  onSelectCity(value) {
    this.cityName = value;
    let json = '{"state":{"state":"' + this.stateName + '","code":"' + this.stateCode +
      '"},"district":"' + this.districtName + '","city":"' + this.cityName + '"}';
    this.TataService.BindTaluka(json).pipe(first()).subscribe((res) => {
      if (res instanceof HttpErrorResponse) {
        return;
      }
      if (res.length > 0) {
        this.TalukaArray = res;
        if (this.talukaName != undefined) {
          this.onSelectTaluka(this.talukaName);
        }
        this.PincodeArray = [];
      } else {
        const errorMessage = res.msg;
        console.log(errorMessage);
        return;
      }
    },
      (error) => {
        console.log(error);
      }
    );
  }

  onSelectTaluka(value) {
    this.talukaName = value;
    let json = '{"state":{"state":"' + this.stateName + '","code":"' + this.stateCode + '"},"district":"' +
      this.districtName + '","city":"' + this.cityName + '","taluka":"' + this.talukaName + '"}';
    this.TataService.BindPincode(json).pipe(first()).subscribe((res) => {
      if (res instanceof HttpErrorResponse) {
        return;
      }
      if (res.pincodes.length > 0) {
        this.PincodeArray = res.pincodes;
      } else {
        const errorMessage = res.msg;
        console.log(errorMessage);
        return;
      }
    },
      (error) => {
        console.log(error);
      }
    );
  }

  onItemDeleted(index: number) {
    this.urls.splice(index, 1);
    this.BindControlArray(this.urls);
  }

  btnsubmitForm() {

    // this.console.log(this.ControlMenuArray);

    // if (this.InventoryForm.invalid) {
    //   Swal.fire("Please fill in the required fields", "");
    //   return;
    // }

    // console.log(this.InventoryForm.value);

    // if (this.DocumentFile == undefined) {
    //   Swal.fire("Please Upload Document");
    //   return;
    // }

    // if (this.InventoryForm.get("InventoryUploadData").value.length == 0) {
    //   Swal.fire("Please Upload Vehicle Image with their position type");
    //   return;
    // }
    // if (this.videoURL.length > 1) {
    //   Swal.fire("Please Upload only 1 video");
    //   return;
    // }

    // var images1 = this.InventoryForm.get('InventoryUploadData').value;
    // var dataFront = images1.filter(book => book.ImagePositionType === 'front');
    // var dataBack = images1.filter(book => book.ImagePositionType === 'back');
    // var datalHs = images1.filter(book => book.ImagePositionType === 'lhs');
    // var datarhs = images1.filter(book => book.ImagePositionType === 'rhs');
    // var datalInside = images1.filter(book => book.ImagePositionType === 'inside_cabin');
    // var datalspped = images1.filter(book => book.ImagePositionType === 'speedometer');
    // var datachs = images1.filter(book => book.ImagePositionType === 'chassis');
    // var datafrt = images1.filter(book => book.ImagePositionType === 'front_right_tyre');
    // var dataflt = images1.filter(book => book.ImagePositionType === 'front_left_tyre');
    // var datarrt = images1.filter(book => book.ImagePositionType === 'rear_right_tyre');
    // var datarlt = images1.filter(book => book.ImagePositionType === 'rear_left_tyre');
    // var dataty5 = images1.filter(book => book.ImagePositionType === 'tyer_5');
    // var dataty6 = images1.filter(book => book.ImagePositionType === 'tyer_6');
    // var dataty7 = images1.filter(book => book.ImagePositionType === 'tyer_7');
    // var dataty8 = images1.filter(book => book.ImagePositionType === 'tyer_8');
    // var dataty9 = images1.filter(book => book.ImagePositionType === 'tyer_9');
    // var dataty10 = images1.filter(book => book.ImagePositionType === 'tyer_10');
    // var dataty11 = images1.filter(book => book.ImagePositionType === 'tyer_11');
    // var dataty12 = images1.filter(book => book.ImagePositionType === 'tyer_12');
    // var dataspare = images1.filter(book => book.ImagePositionType === 'spare');
    // if (dataFront.length > 1) {
    //   Swal.fire("Oops...", "Front poistion select multiple time", "error");
    //   return false
    // }
    // else if (dataBack.length > 1) {
    //   Swal.fire("Oops...", "Front poistion select multiple time", "error");
    //   return false
    // }
    // else if (datalHs.length > 1) {
    //   Swal.fire("Oops...", "Lhs poistion select multiple time", "error");
    //   return false
    // }
    // else if (datarhs.length > 1) {
    //   Swal.fire("Oops...", "Rhs poistion select multiple time", "error");
    //   return false
    // }
    // else if (datalInside.length > 1) {
    //   Swal.fire("Oops...", "Inside cabin poistion select multiple time", "error");
    //   return false
    // }
    // else if (datalspped.length > 1) {
    //   Swal.fire("Oops...", "Speedometer poistion select multiple time", "error");
    //   return false
    // }
    // else if (datachs.length > 1) {
    //   Swal.fire("Oops...", "Chassis poistion select multiple time", "error");
    //   return false
    // }
    // else if (datafrt.length > 1) {
    //   Swal.fire("Oops...", "Front right tyre poistion select multiple time", "error");
    //   return false
    // }
    // else if (dataflt.length > 1) {
    //   Swal.fire("Oops...", "Front left tyre poistion select multiple time", "error");
    //   return false
    // }
    // else if (datarrt.length > 1) {
    //   Swal.fire("Oops...", "Rear right tyre poistion select multiple time", "error");
    //   return false
    // }
    // else if (datarlt.length > 1) {
    //   Swal.fire("Oops...", "Rear left tyre poistion select multiple time", "error");
    //   return false
    // }
    // else if (dataty5.length > 1) {
    //   Swal.fire("Oops...", "Tyre 5 poistion select multiple time", "error");
    //   return false
    // }
    // else if (dataty6.length > 1) {
    //   Swal.fire("Oops...", "Tyre 6 poistion select multiple time", "error");
    //   return false
    // }
    // else if (dataty7.length > 1) {
    //   Swal.fire("Oops...", "Tyre 7 poistion select multiple time", "error");
    //   return false
    // }
    // else if (dataty8.length > 1) {
    //   Swal.fire("Oops...", "Tyre 8 poistion select multiple time", "error");
    //   return false
    // }
    // else if (dataty9.length > 1) {
    //   Swal.fire("Oops...", "Tyre 9 poistion select multiple time", "error");
    //   return false
    // }
    // else if (dataty10.length > 1) {
    //   Swal.fire("Oops...", "Tyre 10 poistion select multiple time", "error");
    //   return false
    // }
    // else if (dataty11.length > 1) {
    //   Swal.fire("Oops...", "Tyre 11 poistion select multiple time", "error");
    //   return false
    // }
    // else if (dataty12.length > 1) {
    //   Swal.fire("Oops...", "Tyre 12 poistion select multiple time", "error");
    //   return false
    // }
    // else if (dataspare.length > 1) {
    //   Swal.fire("Oops...", "Spare tyre poistion select multiple time", "error");
    //   return false
    // }

    // if (this.vehicle_certificate_type == "Non - Certified" && this.InventoryForm.get("InventoryUploadData").value.length > 0) {
    //   var fileAmount = this.InventoryForm.get("InventoryUploadData").value.length;
    //   for (let i = 0; i < fileAmount; i++) {
    //     var ImagePositionType = this.InventoryForm.get("InventoryUploadData").value[i].ImagePositionType;
    //     if (ImagePositionType == "") {
    //       Swal.fire("Please Select Image Postion Type");
    //       return;
    //     }
    //     var images = this.InventoryForm.get('InventoryUploadData').value;
    //     var data2 = images.filter(book => book.ImagePositionType === 'cover_image');

    //     console.log("Images", images)
    //     console.log("Images Position", data2)
    //     if (data2.length == 0) {
    //       Swal.fire("Oops...", 'Please Upload Vehicle Cover Image', "error");
    //       return false
    //     }
    //     else if (data2.length > 1) {
    //       Swal.fire("Oops...", "Cover Image poistion select multiple time", "error");
    //       return false
    //     }

    //   }

    // }
    // else if (this.vehicle_certificate_type == "Certified" && this.InventoryForm.get("InventoryUploadData").value.length >= 21) {
    //   var fileAmount = this.InventoryForm.get("InventoryUploadData").value.length;
    //   for (let i = 0; i < fileAmount; i++) {
    //     //   var images = this.InventoryForm.get('InventoryUploadData').value;
    //     //   var data2 = images.filter(book => book.ImagePositionType === 'cover_image');
    //     // if (data2.length == 0) {
    //     //   Swal.fire("Oops...", 'Please Upload Vehicle Cover Image', "error");
    //     //   return false
    //     // }
    //     // else if(data2.length > 1)
    //     // {
    //     //   Swal.fire("Oops...", "Please select different position for each image", "error");
    //     //   return false
    //     // }
    //     var ImagePositionType = this.InventoryForm.get("InventoryUploadData").value[i].ImagePositionType;
    //     if (ImagePositionType == "") {
    //       Swal.fire("Please Select Image Postion Type");
    //       return;
    //     }
    //   }
    // }
    // else {
    //   Swal.fire("Please upload the image of the vehicle along with their postion type which should not be less than 21");
    //   return;
    // }

    // New Changes
    if (this.InventoryForm.get("InventoryUploadData").value.length == 0) {
      Swal.fire("Please Upload Vehicle Image with their position type");
      return;
    }
    if (this.videoURL.length > 1) {
      Swal.fire("Please Upload only 1 video");
      return;
    }

    var images1 = this.InventoryForm.get('InventoryUploadData').value;
    var dataFront = images1.filter(book => book.ImagePositionType === 'front');
    var dataBack = images1.filter(book => book.ImagePositionType === 'back');
    var datalHs = images1.filter(book => book.ImagePositionType === 'lhs');
    var datarhs = images1.filter(book => book.ImagePositionType === 'rhs');
    var datalInside = images1.filter(book => book.ImagePositionType === 'inside_cabin');
    var datalspped = images1.filter(book => book.ImagePositionType === 'speedometer');
    var datachs = images1.filter(book => book.ImagePositionType === 'chassis');
    var datafrt = images1.filter(book => book.ImagePositionType === 'front_right_tyre');
    var dataflt = images1.filter(book => book.ImagePositionType === 'front_left_tyre');
    var datarrt = images1.filter(book => book.ImagePositionType === 'rear_right_tyre');
    var datarlt = images1.filter(book => book.ImagePositionType === 'rear_left_tyre');
    var dataty5 = images1.filter(book => book.ImagePositionType === 'tyre_5');
    var dataty6 = images1.filter(book => book.ImagePositionType === 'tyre_6');
    var dataty7 = images1.filter(book => book.ImagePositionType === 'tyre_7');
    var dataty8 = images1.filter(book => book.ImagePositionType === 'tyre_8');
    var dataty9 = images1.filter(book => book.ImagePositionType === 'tyre_9');
    var dataty10 = images1.filter(book => book.ImagePositionType === 'tyre_10');
    var dataty11 = images1.filter(book => book.ImagePositionType === 'tyre_11');
    var dataty12 = images1.filter(book => book.ImagePositionType === 'tyre_12');
    var dataspare = images1.filter(book => book.ImagePositionType === 'spare');
    if (dataFront.length > 1) {
      Swal.fire("Oops...", "Front Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataBack.length > 1) {
      Swal.fire("Oops...", "Back Position Is Selected Multiple Times", "error");
      return false
    }
    else if (datalHs.length > 1) {
      Swal.fire("Oops...", "LHS Position Is Selected Multiple Times", "error");
      return false
    }
    else if (datarhs.length > 1) {
      Swal.fire("Oops...", "RHS Position Is Selected Multiple Times", "error");
      return false
    }
    else if (datalInside.length > 1) {
      Swal.fire("Oops...", "Inside Cabin Position Is Selected Multiple Times", "error");
      return false
    }
    else if (datalspped.length > 1) {
      Swal.fire("Oops...", "Speedometer Position Is Selected Multiple Times", "error");
      return false
    }
    else if (datachs.length > 1) {
      Swal.fire("Oops...", "Chassis Position Is Selected Multiple Times", "error");
      return false
    }
    else if (datafrt.length > 1) {
      Swal.fire("Oops...", "Front Right Tyre Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataflt.length > 1) {
      Swal.fire("Oops...", "Front Left Tyre Position Is Selected Multiple Times", "error");
      return false
    }
    else if (datarrt.length > 1) {
      Swal.fire("Oops...", "Rear Right Tyre Position Is Selected Multiple Times", "error");
      return false
    }
    else if (datarlt.length > 1) {
      Swal.fire("Oops...", "Rear Left Tyre Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataty5.length > 1) {
      Swal.fire("Oops...", "Tyre 5 Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataty6.length > 1) {
      Swal.fire("Oops...", "Tyre 6 Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataty7.length > 1) {
      Swal.fire("Oops...", "Tyre 7 Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataty8.length > 1) {
      Swal.fire("Oops...", "Tyre 8 Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataty9.length > 1) {
      Swal.fire("Oops...", "Tyre 9 Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataty10.length > 1) {
      Swal.fire("Oops...", "Tyre 10 Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataty11.length > 1) {
      Swal.fire("Oops...", "Tyre 11 Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataty12.length > 1) {
      Swal.fire("Oops...", "Tyre 12 Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataspare.length > 1) {
      Swal.fire("Oops...", "Spare Tyre Position Is Selected Multiple Times", "error");
      return false
    }

    if (this.vehicle_certificate_type == "Non - Certified" && this.InventoryForm.get("InventoryUploadData").value.length > 0) {
      var fileAmount = this.InventoryForm.get("InventoryUploadData").value.length;
      for (let i = 0; i < fileAmount; i++) {
        var ImagePositionType = this.InventoryForm.get("InventoryUploadData").value[i].ImagePositionType;
        if (ImagePositionType == "") {
          Swal.fire("Please Select Image Position Type");
          return;
        }
        var images = this.InventoryForm.get('InventoryUploadData').value;
        var data2 = images.filter(book => book.ImagePositionType === 'cover_image');

        if (data2.length == 0) {
          Swal.fire("Oops...", 'If Vehicle Is Non-Certified, You Need To Upload At Least 1 Images With Cover Image Position Type', "error");
          return false
        }
        else if (data2.length > 1) {
          Swal.fire("Oops...", "Cover Image Position Is Selected Multiple Times", "error");
          return false
        }

      }

    }

    else if (this.vehicle_certificate_type == "Certified" && this.InventoryForm.get("InventoryUploadData").value.length >= 13) {
      var fileAmount = this.InventoryForm.get("InventoryUploadData").value.length;
      for (let i = 0; i < fileAmount; i++) {
        var ImagePositionType = this.InventoryForm.get("InventoryUploadData").value[i].ImagePositionType;
        if (ImagePositionType == "") {
          Swal.fire("Please Select Image Position Type");
          return;
        }
      }
    }
    else {
      Swal.fire("If Vehicle Is Certified, You Need To Upload At Least 13 Images With Their Position Type");
      return;
    }

    var i = 0;
    this.control_detail_id = [];
    for (var key in this.InventoryForm.value) {
      var ids = parseInt(key);
      let elenames = this.ControlMenuArray.find((x) => x.mapping_id === ids);

      if (key == "category") {
      } else if (key == "Subcategory") {
      } else if (key == "InventoryType") {
      } else if (key == "InventoryUploadData") {
      } else if (elenames.element_name == "Evaluation Report") {
      } else if (elenames.element_name == "Vehicle Image") {
      } else if (elenames.element_name == "Cover Image") {
      } else {
        var id = parseInt(key);
        let elename = this.ControlMenuArray.find((x) => x.mapping_id === id);
        var value = this.InventoryForm.value[key];
        if (elename.element_name == "State") {
          if (value == undefined || value == "") {
            value = "";
          } else {
            value = value;
          }
        } else {
        }

        if (elename.element_name == "Visibility") {
          value = this.CheckedLabel;
        }
        if (elename.element_name == "Vehicle Certificate Type") {
          value = this.vehicle_certificate_type;
        }

        this.control_detail_id.push(
          this.formData(key, value, elename.element_name)
        );
      }
      value = "";
      i++;
    }


    if (this.control_detail_id.length > 0) {
      this.loader.open();
      const InventoryMasterData: InventoryMaster = {} as InventoryMaster;
      var PartyId = 1;
      InventoryMasterData.user_id = parseInt(this.commonService.getUserCode());
      InventoryMasterData.cat_id = parseInt(this.InventoryForm.value.InventoryType.cat_id);
      InventoryMasterData.sub_cat_id = parseInt(this.InventoryForm.value.category.sub_cat_id);
      InventoryMasterData.sub_cat_detail_id = parseInt(this.InventoryForm.value.Subcategory.sub_cat_detail_id);
      InventoryMasterData.control_details = this.control_detail_id;
      // InventoryMasterData.vehicle_certificate_type = this.vehicle_certificate_type;
      let json = InventoryMasterData;
      this.InvenService.AddInventory(InventoryMasterData).pipe(first()).subscribe(
        (res) => {
          if (res instanceof HttpErrorResponse) {
            return;
          }
          if (res.success) {
            this.ApproveInventory(res.data.inventory_id);
            var Check = false;
            const formData = new FormData();
            formData.append("user_code", this.commonService.getUserCode1());
            formData.append("inventory_code", res.data.inventory_code);
            formData.append("contract_type", "inventory");

            for (let i of this.InventoryForm.get("InventoryUploadData").value) {
              formData.append(i.ImagePositionType, i.FileName);
            }
    //         console.log(this.InventoryForm.value);
    // console.log(this.TechnicalDetailsArray[3].content);
    const filteredData = this.TechnicalDetailsArray[3].content.filter(item => item.element_name == "Dealer Suggestive Price");
    // console.log(filteredData);
    const result = this.getValueByKey(filteredData[0].mapping_id,this.InventoryForm.value);
    // console.log("MSRP",result);
    // console.log("MSRP", result > 0 ? true : false);

      if (result > 0) {
        this.updateDealerPrice(res.data.inventory_code,result)
      }
            Check = true;
            if (Check) {
              this.FileUpService.uploadInventory(formData).subscribe(
                (data) => {
                  if (data.success) {
                    this.InventoryForm.reset();
                    // Swal.fire("Successfully saved!");
                    // this.router.navigate(['pages/InventoryList'])
                    // setTimeout(() => {
                    // this.router.navigate(['pages/InventoryList']);
                    //},
                    // 1000);
                    if (this.videoURL.length > 0) {
                      const data = new FormData();
                      data.append("id", res.data.inventory_id);
                      // data.append("video_s", this.url);
                      for (let i of this.videoURL) {
                        data.append("video_s", i);
                      }

                      this.FileUpService.UploadVideo(data).subscribe(
                        (data) => {
                          if (data.success) {
                            this.InventoryForm.reset();
                            this.loader.close();
                            Swal.fire("Successfully saved!");
                            this.router.navigate(["pages/InventoryList"]);
                          } else {
                            this.loader.close();
                            Swal.fire("UnSuccessfully saved! " + data.data.msg);
                          }
                        }
                      );
                    } else {
                      this.loader.close();
                      Swal.fire("Successfully saved!");
                      this.router.navigate(["pages/InventoryList"]);
                    }
                  } else {
                    this.loader.close();
                    Swal.fire("UnSuccessfully saved! " + data.data.msg);
                  }
                  // this.loader.close();
                  // this.router.navigate(["pages/InventoryList"]);
                }
              );
            } else {
              this.loader.close();
            }


            // VIDEO CHECK



          } else {
            this.loader.close();
            Swal.fire(res.data.msg, "Error");
          }
        },
        (error) => {
          this.loader.close();
          Swal.fire(error.error.data.msg, "Error");
        }
      );
    }
  }

  btnUpdateForm() {
    // if (this.InventoryForm.invalid) {
    //   Swal.fire("Please fill in the required fields", "");
    //   // return;
    // }
    //    console.log(this.InventoryForm.value);
    // if (this.DocumentFile == undefined) {
    //   if (this.EReport == undefined) {
    //     Swal.fire("Please Upload Document");
    //     return;
    //   }
    // }
    // var images1 = this.InventoryForm.get('InventoryUploadData').value;
    // var dataFront = images1.filter(book => book.ImagePositionType === 'front');
    // var dataBack = images1.filter(book => book.ImagePositionType === 'back');
    // var datalHs = images1.filter(book => book.ImagePositionType === 'lhs');
    // var datarhs = images1.filter(book => book.ImagePositionType === 'rhs');
    // var datalInside = images1.filter(book => book.ImagePositionType === 'inside_cabin');
    // var datalspped = images1.filter(book => book.ImagePositionType === 'speedometer');
    // var datachs = images1.filter(book => book.ImagePositionType === 'chassis');
    // var datafrt = images1.filter(book => book.ImagePositionType === 'front_right_tyre');
    // var dataflt = images1.filter(book => book.ImagePositionType === 'front_left_tyre');
    // var datarrt = images1.filter(book => book.ImagePositionType === 'rear_right_tyre');
    // var datarlt = images1.filter(book => book.ImagePositionType === 'rear_left_tyre');
    // var dataty5 = images1.filter(book => book.ImagePositionType === 'tyre_5');
    // var dataty6 = images1.filter(book => book.ImagePositionType === 'tyre_6');
    // var dataty7 = images1.filter(book => book.ImagePositionType === 'tyre_7');
    // var dataty8 = images1.filter(book => book.ImagePositionType === 'tyre_8');
    // var dataty9 = images1.filter(book => book.ImagePositionType === 'tyre_9');
    // var dataty10 = images1.filter(book => book.ImagePositionType === 'tyre_10');
    // var dataty11 = images1.filter(book => book.ImagePositionType === 'tyre_11');
    // var dataty12 = images1.filter(book => book.ImagePositionType === 'tyre_12');
    // var dataspare = images1.filter(book => book.ImagePositionType === 'spare');
    // var data2 = images1.filter(book => book.ImagePositionType === 'cover_image');

    // if (this.InventoryForm.get("InventoryUploadData").value.length == 0) {
    //   Swal.fire("Please Upload Vehicle Image with their position type");
    //   return;
    // }

    // if (data2.length == 0) {
    //   Swal.fire("Oops...", 'Please Upload Vehicle Cover Image', "error");
    //   return false
    // }
    // else if (data2.length > 1) {
    //   Swal.fire("Oops...", "Cover Image poistion select multiple time", "error");
    //   return false
    // }

    // if (dataFront.length > 1) {
    //   Swal.fire("Oops...", "Front poistion select multiple time", "error");
    //   return false
    // }
    // else if (dataBack.length > 1) {
    //   Swal.fire("Oops...", "Front poistion select multiple time", "error");
    //   return false
    // }
    // else if (datalHs.length > 1) {
    //   Swal.fire("Oops...", "Lhs poistion select multiple time", "error");
    //   return false
    // }
    // else if (datarhs.length > 1) {
    //   Swal.fire("Oops...", "Rhs poistion select multiple time", "error");
    //   return false
    // }
    // else if (datalInside.length > 1) {
    //   Swal.fire("Oops...", "Inside cabin poistion select multiple time", "error");
    //   return false
    // }
    // else if (datalspped.length > 1) {
    //   Swal.fire("Oops...", "Speedometer poistion select multiple time", "error");
    //   return false
    // }
    // else if (datachs.length > 1) {
    //   Swal.fire("Oops...", "Chassis poistion select multiple time", "error");
    //   return false
    // }
    // else if (datafrt.length > 1) {
    //   Swal.fire("Oops...", "Front right tyre poistion select multiple time", "error");
    //   return false
    // }
    // else if (dataflt.length > 1) {
    //   Swal.fire("Oops...", "Front left tyre poistion select multiple time", "error");
    //   return false
    // }
    // else if (datarrt.length > 1) {
    //   Swal.fire("Oops...", "Rear right tyre poistion select multiple time", "error");
    //   return false
    // }
    // else if (datarlt.length > 1) {
    //   Swal.fire("Oops...", "Rear left tyre poistion select multiple time", "error");
    //   return false
    // }
    // else if (dataty5.length > 1) {
    //   Swal.fire("Oops...", "Tyre 5 poistion select multiple time", "error");
    //   return false
    // }
    // else if (dataty6.length > 1) {
    //   Swal.fire("Oops...", "Tyre 6 poistion select multiple time", "error");
    //   return false
    // }
    // else if (dataty7.length > 1) {
    //   Swal.fire("Oops...", "Tyre 7 poistion select multiple time", "error");
    //   return false
    // }
    // else if (dataty8.length > 1) {
    //   Swal.fire("Oops...", "Tyre 8 poistion select multiple time", "error");
    //   return false
    // }
    // else if (dataty9.length > 1) {
    //   Swal.fire("Oops...", "Tyre 9 poistion select multiple time", "error");
    //   return false
    // }
    // else if (dataty10.length > 1) {
    //   Swal.fire("Oops...", "Tyre 10 poistion select multiple time", "error");
    //   return false
    // }
    // else if (dataty11.length > 1) {
    //   Swal.fire("Oops...", "Tyre 11 poistion select multiple time", "error");
    //   return false
    // }
    // else if (dataty12.length > 1) {
    //   Swal.fire("Oops...", "Tyre 12 poistion select multiple time", "error");
    //   return false
    // }
    // else if (dataspare.length > 1) {
    //   Swal.fire("Oops...", "Spare tyre poistion select multiple time", "error");
    //   return false
    // }

    // if (this.InventoryForm.get("InventoryUploadData").value.length == 0) {
    //   Swal.fire("Please Select Product Image");
    //   return;
    // } else {
    //   var fileAmount = this.InventoryForm.get("InventoryUploadData").value.length;
    //   for (let i = 0; i < fileAmount; i++) {
    //     var ImagePositionType = this.InventoryForm.get("InventoryUploadData").value[i].ImagePositionType;
    //     if (ImagePositionType == "") {
    //       Swal.fire("Please Select Product Image Postion Type");
    //       return;
    //     }
    //   }
    // }
if (this.approval_status == true) {
  Swal.fire({
    toast: true,
    icon: 'warning',
    title: 'This vehicle has already been approved by TSM. If you wish to edit it, please mark the vehicle as inactive first.',
    position: 'top-end',
    showConfirmButton: false,
    timer: 1500,
    // timerProgressBar: true,
  });
}
else{


    if (this.InventoryForm.get("InventoryUploadData").value.length == 0) {
      Swal.fire("Please Upload Vehicle Image with their position type");
      return;
    }
    if (this.videoURL.length > 1) {
      Swal.fire("Please Upload only 1 video");
      return;
    }

    var images1 = this.InventoryForm.get('InventoryUploadData').value;
    var dataFront = images1.filter(book => book.ImagePositionType === 'front');
    var dataBack = images1.filter(book => book.ImagePositionType === 'back');
    var datalHs = images1.filter(book => book.ImagePositionType === 'lhs');
    var datarhs = images1.filter(book => book.ImagePositionType === 'rhs');
    var datalInside = images1.filter(book => book.ImagePositionType === 'inside_cabin');
    var datalspped = images1.filter(book => book.ImagePositionType === 'speedometer');
    var datachs = images1.filter(book => book.ImagePositionType === 'chassis');
    var datafrt = images1.filter(book => book.ImagePositionType === 'front_right_tyre');
    var dataflt = images1.filter(book => book.ImagePositionType === 'front_left_tyre');
    var datarrt = images1.filter(book => book.ImagePositionType === 'rear_right_tyre');
    var datarlt = images1.filter(book => book.ImagePositionType === 'rear_left_tyre');
    var dataty5 = images1.filter(book => book.ImagePositionType === 'tyre_5');
    var dataty6 = images1.filter(book => book.ImagePositionType === 'tyre_6');
    var dataty7 = images1.filter(book => book.ImagePositionType === 'tyre_7');
    var dataty8 = images1.filter(book => book.ImagePositionType === 'tyre_8');
    var dataty9 = images1.filter(book => book.ImagePositionType === 'tyre_9');
    var dataty10 = images1.filter(book => book.ImagePositionType === 'tyre_10');
    var dataty11 = images1.filter(book => book.ImagePositionType === 'tyre_11');
    var dataty12 = images1.filter(book => book.ImagePositionType === 'tyre_12');
    var dataspare = images1.filter(book => book.ImagePositionType === 'spare');
    if (dataFront.length > 1) {
      Swal.fire("Oops...", "Front Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataBack.length > 1) {
      Swal.fire("Oops...", "Back Position Is Selected Multiple Times", "error");
      return false
    }
    else if (datalHs.length > 1) {
      Swal.fire("Oops...", "LHS Position Is Selected Multiple Times", "error");
      return false
    }
    else if (datarhs.length > 1) {
      Swal.fire("Oops...", "RHS Position Is Selected Multiple Times", "error");
      return false
    }
    else if (datalInside.length > 1) {
      Swal.fire("Oops...", "Inside Cabin Position Is Selected Multiple Times", "error");
      return false
    }
    else if (datalspped.length > 1) {
      Swal.fire("Oops...", "Speedometer Position Is Selected Multiple Times", "error");
      return false
    }
    else if (datachs.length > 1) {
      Swal.fire("Oops...", "Chassis Position Is Selected Multiple Times", "error");
      return false
    }
    else if (datafrt.length > 1) {
      Swal.fire("Oops...", "Front Right Tyre Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataflt.length > 1) {
      Swal.fire("Oops...", "Front Left Tyre Position Is Selected Multiple Times", "error");
      return false
    }
    else if (datarrt.length > 1) {
      Swal.fire("Oops...", "Rear Right Tyre Position Is Selected Multiple Times", "error");
      return false
    }
    else if (datarlt.length > 1) {
      Swal.fire("Oops...", "Rear Left Tyre Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataty5.length > 1) {
      Swal.fire("Oops...", "Tyre 5 Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataty6.length > 1) {
      Swal.fire("Oops...", "Tyre 6 Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataty7.length > 1) {
      Swal.fire("Oops...", "Tyre 7 Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataty8.length > 1) {
      Swal.fire("Oops...", "Tyre 8 Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataty9.length > 1) {
      Swal.fire("Oops...", "Tyre 9 Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataty10.length > 1) {
      Swal.fire("Oops...", "Tyre 10 Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataty11.length > 1) {
      Swal.fire("Oops...", "Tyre 11 Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataty12.length > 1) {
      Swal.fire("Oops...", "Tyre 12 Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataspare.length > 1) {
      Swal.fire("Oops...", "Spare Tyre Position Is Selected Multiple Times", "error");
      return false
    }

    if (this.vehicle_certificate_type == "Non - Certified" && this.InventoryForm.get("InventoryUploadData").value.length > 0) {
      var fileAmount = this.InventoryForm.get("InventoryUploadData").value.length;
      for (let i = 0; i < fileAmount; i++) {
        var ImagePositionType = this.InventoryForm.get("InventoryUploadData").value[i].ImagePositionType;
        if (ImagePositionType == "") {
          Swal.fire("Please Select Image Position Type");
          return;
        }
        var images = this.InventoryForm.get('InventoryUploadData').value;
        var data2 = images.filter(book => book.ImagePositionType === 'cover_image');

        if (data2.length == 0) {
          Swal.fire("Oops...", 'If Vehicle Is Non-Certified, You Need To Upload At Least 1 Images With Cover Image Position Type', "error");
          return false
        }
        else if (data2.length > 1) {
          Swal.fire("Oops...", "Cover Image Position Is Selected Multiple Times", "error");
          return false
        }
      }
    }

    else if (this.vehicle_certificate_type == "Certified" && this.InventoryForm.get("InventoryUploadData").value.length >= 13) {
      var fileAmount = this.InventoryForm.get("InventoryUploadData").value.length;
      for (let i = 0; i < fileAmount; i++) {
        var ImagePositionType = this.InventoryForm.get("InventoryUploadData").value[i].ImagePositionType;
        if (ImagePositionType == "") {
          Swal.fire("Please Select Image Position Type");
          return;
        }
      }
    }
    else {
      Swal.fire("If Vehicle Is Certified, You Need To Upload At Least 13 Images With Their Position Type");
      return;
    }

    var i = 0;
    this.control_detail_id = [];
    for (var key in this.InventoryForm.value) {
      var ids = parseInt(key);
      let elenames = this.ControlMenuArray.find((x) => x.mapping_id === ids);
      if (key == "category") {
      } else if (key == "Subcategory") {
      } else if (key == "InventoryType") {
      } else if (key == "InventoryUploadData") {
      } else if (elenames.element_name == "Evaluation Report") {
      } else if (elenames.element_name == "Vehicle Image") {
      } else if (elenames.element_name == "Cover Image") {
      } else {
        var id = parseInt(key);

        let elename = this.ControlMenuArray.find((x) => x.mapping_id === id);
        var value = this.InventoryForm.value[key];
        if (elename.element_name == "State") {
          if (value == undefined || value == "") {
            value = "";
          } else {
            value = value;
          }
        } else {
        }

        if (elename.element_name == "Visibility") {
          value = this.CheckedLabel;
        }

        if (elename.element_name == "Vehicle Certificate Type") {
          value = this.vehicle_certificate_type;
        }

        this.control_detail_id.push(
          this.formData(key, value, elename.element_name)
        );
      }
      value = "";
      i++;
    }
    if (this.control_detail_id.length > 0) {
      this.loader.open();
      const InventoryMasterData: InventoryMaster = {} as InventoryMaster;
      var PartyId = 1;
      InventoryMasterData.id = parseInt(this.InventoryID);
      InventoryMasterData.user_id = parseInt(this.commonService.getUserCode());
      InventoryMasterData.cat_id = parseInt(this.InventoryForm.value.InventoryType.cat_id);
      InventoryMasterData.sub_cat_id = parseInt(this.InventoryForm.value.category.sub_cat_id);
      InventoryMasterData.sub_cat_detail_id = parseInt(this.InventoryForm.value.Subcategory.sub_cat_detail_id);
      InventoryMasterData.control_details = this.control_detail_id;
      InventoryMasterData.vehicle_certificate_type = this.vehicle_certificate_type;
      let json = InventoryMasterData;
      this.InvenService.UpdateInventoryById(InventoryMasterData).pipe(first()).subscribe((res) => {
        if (res instanceof HttpErrorResponse) {
          return;
        }
        if (res.success) {

          var Check = false;
          const formData = new FormData();
          formData.append("user_code", this.commonService.getUserCode1());
          formData.append("inventory_code", res.data.inventory_code);
          formData.append("contract_type", "inventory");
          if (this.DocumentFile == undefined) {
          } else {
            formData.append("evaluation_criteria", this.DocumentFile);
            Check = true;
          }

          for (let i of this.InventoryForm.get("InventoryUploadData")
            .value) {
            var filename = i.FileName;
            if (i.ImageType == "NEW") {
              formData.append(i.ImagePositionType, i.FileName);
              Check = true;
            }
          }

          var videoCheck = false;
          if (this.videoURL.length > 0) {
            videoCheck = true;
          }

          if (Check) {
            this.FileUpService.uploadInventory(formData).subscribe(
              (data) => {
                if (data.success) {
                  this.loader.close();
                  this.InventoryForm.reset();
                  //  Swal.fire('Update Inventory Successfully!');

                  if (this.videoURL.length > 0) {
                    const data = new FormData();
                    data.append("id", res.data.inventory_id);
                    // data.append("video_s", this.url);
                    for (let i of this.videoURL) {
                      data.append("video_s", i);
                    }
                    this.FileUpService.UploadVideo(data).subscribe(
                      (data) => {
                        if (data.success) {
                          this.InventoryForm.reset();
                          this.loader.close();
                          // Swal.fire("Updated successfully!");
                          // this.router.navigate(['pages/InventoryList']);
                        } else {
                          this.loader.close();
                          Swal.fire("UnSuccessfully saved! " + data.data.msg);
                        }
                      }
                    );
                  } else {
                    this.loader.close();
                    // Swal.fire("Updated successfully!");
                    // this.router.navigate(['pages/InventoryList']);
                  }

                } else {
                  Swal.fire("Unsuccessfully Updated! " + data.data.msg);
                }
                // this.router.navigate(['pages/InventoryList']);
              }
            );
          }
          else if (videoCheck) {
            const data = new FormData();
            data.append("id", res.data.inventory_id);
            // data.append("video_s", this.url);
            for (let i of this.videoURL) {
              data.append("video_s", i);

            }
            this.FileUpService.UploadVideo(data).subscribe(
              (data) => {
                if (data.success) {
                  this.InventoryForm.reset();
                  this.loader.close();
                  // Swal.fire("Updated successfully!");
                  // this.router.navigate(["pages/InventoryList"]);
                } else {
                  this.loader.close();
                  Swal.fire("UnSuccessfully saved! " + data.data.msg);
                }
              }
            );
          }
          this.loader.close();
          Swal.fire("Updated successfully!");
          this.router.navigate(["pages/InventoryList"]);
        } else {
          this.loader.close();
          Swal.fire(res.data.msg);
        }
      },
        (error) => {
          this.loader.close();
          Swal.fire(error.error.data.msg);
        }
      );
    }
  }
  }

  formData(key, value, elementid): ControlDetail {
    const ControlDetailByMapId: ControlDetail = {} as ControlDetail;
    ControlDetailByMapId.cat_mapid = parseInt(key);
    ControlDetailByMapId.control_detail_id = 1;
    ControlDetailByMapId.control_detail_value = value;
    ControlDetailByMapId.element_detail_value = elementid;
    return ControlDetailByMapId;
  }

  formElementData(elementid, elementvalue): ElementData {
    const ElementArrayData: ElementData = {} as ElementData;
    ElementArrayData.element_id = elementid;
    ElementArrayData.element_name = elementvalue;
    return ElementArrayData;
  }

  onClear() {
    this.showForm = false;
    this.VechileInfoArray = [];
    this.DealerDetailsArray = [];
    this.AddressArray = [];
    this.VehicleInteriorDetailsArray = [];
    this.VehicleDetailsArray = [];
    this.MakeModelDetailsArray = [];
    this.RegistrationDetailsArray = [];
    this.InsuranceOtherDetailsArray = [];
    this.ImagesReportsArray = [];
    this.OtherDetailsArray = [];
    this.BasicDetailsArray = [];
    this.TechnicalDetailsArray = [];
    this.OtherArray = [];
    this.ControlMenuArray = [];
    this.imageSrcUrls = [];
    this.urls = [];
    this.InventoryForm.reset();
  }
  urls = new Array<string>();

  SelectImageFiles(event) {

    this.urls = [];
    let files = event.target.files;
    let imageArray = [];
    imageArray.push(files)

    var ImageIength = files.length + this.InventoryForm.get("InventoryUploadData").value.length
    if (files.length <= 21) {
      if (ImageIength <= 21) {
        for (let file1 of files) {
          var Extension = event.target.files[0].name
            .substring(event.target.files[0].name.lastIndexOf(".") + 1).toLowerCase();
          const img = new Image();
          img.src = window.URL.createObjectURL(file1);
          if (Extension == "JPEG" || Extension == "jpeg" || Extension == "jpg" || Extension == "png"
            || Extension == "JPG" || Extension == "PNG") {
            const file = file1
            const width = img.naturalWidth
            const height = img.naturalHeight;
            if (file.size < 5000000) {
              this.AllImageFile.push(file);
              this.BindControlArray(file1);
              let reader = new FileReader();
              reader.onload = (e: any) => {
                this.urls.push(e.target.result);
              };
              reader.readAsDataURL(file);
            }
            else {
              Swal.fire("File size should not be more than 5 MB.");
              return false;
            }
          }
          else {
            Swal.fire("Only JPEG,PNG and JPG files are allowed.");
            return false;
          }


          // this.AllImageFile.push(file);
          // let reader = new FileReader();
          // reader.onload = (e: any) => {
          //   this.urls.push(e.target.result);
          // };
          // reader.readAsDataURL(file);
        }
        // if ((this.urls.length = files.length)) {
        //   this.BindControlArray(files);
        // }
      }
      else {
        // this.AllImageFile=[];
        Swal.fire({
          icon: 'info',
          title: 'Max. allowed images = 21 Images'
        })
      }
    }
    else {
      // this.AllImageFile = [];
      Swal.fire({
        icon: 'info',
        title: 'Max. allowed images = 21 Images'
      })
    }
  }


  SelectDocumentFiles(event) {
    if (event.target.files && event.target.files[0]) {
      var Extension = event.target.files[0].name
        .substring(event.target.files[0].name.lastIndexOf(".") + 1)
        .toLowerCase();
      const reader = new FileReader();
      const file = event.target.files[0];
      this.DocumentFile = file;
      this.AttachementFile = file.name;
      if (file.size < 5000000) {
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event) => {
          let target: any = event.target;
        };
      } else {
        Swal.fire("Oops...", "Upload only 5 MB size files!", "error");
      }
    }
  }

  form_data_for_image_upload(file_list) {
    let temp = "";
    let first = false;
    let array_file_temp = [];
    let second = false;
    let file_data_final = [];
    let i = 0;
    for (let file of file_list) {
      i = i + 1;
      if (!first) {
        temp = file.ImagePositionType;
        first = true;
      }
      if (temp != file.ImagePositionType) {
        const dataList: FormArrayForFile = {} as FormArrayForFile;
        dataList.stage = temp;
        dataList.formData = array_file_temp;
        file_data_final.push(dataList);
        array_file_temp = [];
      }
      temp = file.ImagePositionType;
      if (temp == file.ImagePositionType) {
        array_file_temp.push(file.FileName);
      }
      if (i == file_list.length) {
        const dataList: FormArrayForFile = {} as FormArrayForFile;
        dataList.stage = temp;
        dataList.formData = array_file_temp;
        file_data_final.push(dataList);
      }
    }
    return file_data_final;
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var Extension = event.target.files[0].name
        .substring(event.target.files[0].name.lastIndexOf(".") + 1)
        .toLowerCase();
      // if (Extension == 'JPEG' || Extension == 'jpg' || Extension == 'png') {
      const reader = new FileReader();
      const file = event.target.files[0];
      //this.imageSrcUrls.push(file);
      if (file.size < 5000000) {
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event) => {
          let target: any = event.target;
          this.imageCompress
            .compressFile(target.result, -2, 50, 50)
            .then((result) => {
              var imageBase64 = result;
              var binary = this.base64toBlob(result, "image/jpeg");
              var blob_ = new Blob([binary], { type: "image/jpeg" });
              var file = new File([blob_], "test.jpg", { type: "image/jpeg" });
              const reader = new FileReader();
              const filecompress = file;
              reader.readAsDataURL(filecompress);
              reader.onload = (event) => {
                let target: any = event.target;
                this.imageSrc = imageBase64;
                //this.imageSrcUrls.push(imageBase64);
                //  this.firstFormGroup.get('imageUrl').setValue(imageBase64);
              };
              //  this.firstFormGroup.get('image').setValue(filecompress);
              // this.firstFormGroup.get('updatedImageSatus').setValue('1');
            });
        };
      } else {
        Swal.fire("Oops...", "Upload only 5 MB size files!", "error");
      }

      // }
      // else {

      //   Swal.fire('Oops...', 'Photo only allows file types of  PNG, JPG and JPEG.', 'error')
      // }
    }
  }

  vehicle_certificate_type: any;
  lobName: string;
  pplName: string;
  onSearchChange(searchValue: string): void {
    var json = {
      offset: 0,
      size: 1000,
      chassis_number: searchValue,
      dev_id: "897jhjh6",
      app_version: "1.2",
      app_name: "com.tatamotors.catlog",
    };
    this.commonService.BindChassisVcData(json).subscribe(
      (data) => {
        this.Data = data;
        // this.lobName = data.lob;
        // this.pplName = data.ppl;
        if (data.vehicle_certificate_type == "" || data.vehicle_certificate_type == null ||
          data.vehicle_certificate_type == undefined || data.vehicle_certificate_type == "Non - Certified") {
          this.vehicle_certificate_type = "Non - Certified"
        }
        else {
          this.vehicle_certificate_type = data.vehicle_certificate_type
        }
        let DealerDetails = JSON.parse(localStorage.getItem('dealerDetails'))
        for (var key in this.InventoryForm.value) {
          var ids = parseInt(key);
          this.MapId = ids;

          let elenames = this.ControlMenuArray.find(
            (x) => x.mapping_id === ids);

          if (elenames != undefined) {
            if (elenames.element_name == "Customer Name") {
              this.InventoryForm.get(key).setValue((data.first_name).trim() + ' ' + (data.last_name).trim());
            } if (elenames.element_name == "Category") {
              this.InventoryForm.get(key).setValue(data.lob);
            } if (elenames.element_name == "Sub Category") {
              this.InventoryForm.get(key).setValue(data.ppl);
            } if (elenames.element_name == "Color") {
              this.InventoryForm.get(key).setValue(data.color);
            } if (elenames.element_name == "Vehicle Type") {
              this.InventoryForm.get(key).setValue(data.model_name);
            } else if (elenames.element_name == "Registration Type") {
              this.InventoryForm.get(key).setValue(data.brand_type);
            } else if (elenames.element_name == "Veh registration Number") {
              this.InventoryForm.get(key).setValue(data.registration_number);
            } else if (elenames.element_name == "Insurance Type") {
              this.InventoryForm.get(key).setValue(data.insurance_type);
            } else if (elenames.element_name == "Fuel Type") {
              this.InventoryForm.get(key).setValue(data.fuel_type);
            } else if (elenames.element_name == "Chassis No") {
              this.InventoryForm.get(key).setValue(data.chassis_number);
            } else if (elenames.element_name == "RC Book") {
              this.InventoryForm.get(key).setValue(data.rc_book_status);
            } else if (elenames.element_name == "Body Type") {
              this.InventoryForm.get(key).setValue(data.body_type);
            } else if (elenames.element_name == "Brand") {
              this.InventoryForm.get(key).setValue(data.brand_type);
            } else if (elenames.element_name == "Road Tax Type") {
              this.InventoryForm.get(key).setValue(data.road_tax_type);
            } else if (elenames.element_name == "RTO Fitness Cert") {
              this.InventoryForm.get(key).setValue(data.rto_fit_cert);
            } else if (elenames.element_name == "Permit") {
              this.InventoryForm.get(key).setValue(data.permit);
            } else if (elenames.element_name == "Odometer") {
              this.InventoryForm.get(key).setValue(data.last_serv_km);
            } else if (elenames.element_name == "Inventory Title") {
              this.InventoryForm.get(key).setValue(data.model_name);
            } else if (elenames.element_name == "Dealer Contact Number") {
              this.InventoryForm.get(key).setValue(DealerDetails.mobile_no);
            } else if (elenames.element_name == "Dealer Contact Person Name") {
              this.InventoryForm.get(key).setValue(DealerDetails.dealerName);
            } else if (elenames.element_name == "Remarks") {
              this.InventoryForm.get(key).setValue(data.vehicle_condition);
            }
            else if (elenames.element_name == "State") {
              let stateName = data.state
              this.InventoryForm.get(key).setValue(data.state);
            }
            else if (elenames.element_name == "District") {
              this.InventoryForm.get(key).setValue(data.district);
            } else if (elenames.element_name == "City") {
              this.InventoryForm.get(key).setValue(data.city);
            } else if (elenames.element_name == "Taluka") {
              this.InventoryForm.get(key).setValue(data.taluka);
            } else if (elenames.element_name == "Pincode") {
              this.InventoryForm.get(key).setValue(data.pin_code);
            } else if (elenames.element_name == "Year of Manufacture") {
              let date = this.datepipe.transform(data.mfg_date, 'yyyy');
              this.InventoryForm.get(key).setValue(date);
            }

            else if (elenames.element_name == "No of Owners") {
              var owner
              if (data.no_owner == 1) { owner = "First Owner" }
              else if (data.no_owner == 2) { owner = "Second Owner" }
              else if (data.no_owner == 3) { owner = "Third Owner" }
              else if (data.no_owner == 4) { owner = "Fourth Owner" }
              else if (data.no_owner == 5) { owner = "Fifth Owner" }
              this.InventoryForm.get(key).setValue(owner);
            }

            else if (elenames.element_name == "Vehicle Registered By") {
              this.InventoryForm.get(key).setValue(data.vehice_type);
            } else if (elenames.element_name == "Vehicle Condition") {
              this.InventoryForm.get(key).setValue(data.vehicle_condition);
            } else if (elenames.element_name == "VC Number") {
              this.InventoryForm.get(key).setValue(data.vc_number);
            }

            if (elenames.element_name == "MSRP") {

              this.InventoryForm.get(key).setValue(data.msrp);
            } else if (elenames.element_name == "Dealer Price") {
              if (data.dlr_selling_price == "" || data.dlr_selling_price == null || data.dlr_selling_price == undefined) {

                var selling_price = data.dlr_selling_price
              }
              else {
                var selling_price = data.dlr_selling_price.toFixed()
              }
              this.InventoryForm.get(key).setValue(selling_price);
            } else if (elenames.element_name == "Insurance Exp Date") {
              this.InventoryForm.get(key).setValue(new Date(data.insurance_valid_date));
            } else if (elenames.element_name == "Date of Registration") {
              this.InventoryForm.get(key).setValue(new Date(data.registration_date));
            } else if (elenames.element_name == "Tax Validity Date") {
              this.InventoryForm.get(key).setValue(new Date(data.road_tax_valid_date));
            } else if (elenames.element_name == "Date of Last RTO Registration") {
              this.InventoryForm.get(key).setValue(new Date(data.registration_date));
            } else if (elenames.element_name == "Fitness Validity Date") {
              this.InventoryForm.get(key).setValue(new Date(data.wrnty_last_date));
            } else if (elenames.element_name == "Road Tax Validity Date") {
              this.InventoryForm.get(key).setValue(new Date(data.road_tax_valid_date));
            }
            else if (elenames.element_name == "GVW") {
              this.InventoryForm.get(key).setValue(this.GetStockData.grows_weight);
            }
            else if (elenames.element_name == "Vehicle Category") {
              this.InventoryForm.get(key).setValue(this.GetStockData.Vehicle_cat);
            }
            else if (elenames.element_name == "HSN") {
              this.InventoryForm.get(key).setValue(this.GetStockData.hsn);
            }
            else if (elenames.element_name == "Varient") {
              this.InventoryForm.get(key).setValue(data.model_name);
            }
            else if (elenames.element_name == "Model") {
              this.InventoryForm.get(key).setValue(data.ppl);
            }
            else if (elenames.element_name == "Customer Name") {
              this.InventoryForm.get(key).setValue(data.first_name + ' ' + data.last_name);
            }
          }
        }


      },
      (err) => {
        for (var key in this.InventoryForm.value) {
          var ids = parseInt(key);
          let elenames = this.ControlMenuArray.find(
            (x) => x.mapping_id === ids
          );
          if (elenames != undefined) {
            if (elenames.element_name == "Customer Name") {
              this.InventoryForm.get(key).setValue("");
            } else if (elenames.element_name == "Registration Type") {
              this.InventoryForm.get(key).setValue("");
            } else if (elenames.element_name == "Registration Number") {
              this.InventoryForm.get(key).setValue("");
            } else if (elenames.element_name == "Model") {
              this.InventoryForm.get(key).setValue("");
            } else if (elenames.element_name == "Variant") {
              this.InventoryForm.get(key).setValue("");
            } else if (elenames.element_name == "Fuel Type") {
              this.InventoryForm.get(key).setValue("");
            } else if (elenames.element_name == "Chassis No") {
              this.InventoryForm.get(key).setValue("");
            } else if (elenames.element_name == "Insurance Exp Date") {
              this.InventoryForm.get(key).setValue("");
            } else if (elenames.element_name == "Tax Validity date") {
              this.InventoryForm.get(key).setValue("");
            } else if (elenames.element_name == "RC Book") {
              this.InventoryForm.get(key).setValue("");
            } else {
            }
          }
        }
        var msg = "";
        var errorMessage = err.error.msg;
        if (errorMessage != undefined) {
          msg = errorMessage;
        } else {
          msg = err.error.detail;
        }
        Swal.fire(msg);
      }
    );
  }

  base64toBlob(base64Data, contentType) {
    contentType = contentType || "";
    var sliceSize = 1024;
    var byteCharacters = atob(
      base64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
    );
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  public fileurls: File;
  myFile: File; /* property of File type */
  fileChange(files: any) {

    this.myFile = files[0].nativeElement;
  }
  Editfiles: any;
  onFileLoad(e) {
    const file = e.target.files[0];
    let fileReader = new FileReader();
    fileReader.onload = () => {
      this.Editfiles = {
        name: file.name,
        size: file.size,
        type: file.type,
        data: fileReader.result,
        isUploading: false,
      };
    };
  }

  EditFile: File;
  imageLength: any
  EditBindControlArray(data) {
    var datasss;
    this.InventoryForm["InventoryUploadData"] = [];
    this.Edit = "Yes"
    for (var i = 0; i < data.length; i++) {
      if (data[i].file_type == "video") {
        var vid_url = this._sanitizer.bypassSecurityTrustResourceUrl(data[i].path);
        var json = {
          "Safurl": vid_url,
          "mainurl": data[i].path,
          "status": "old"
        }
        this.video_url_sDisplay.push(json)
      }
      else if (data[i].file_type == "image") {
        var position = data[i].position;
        if (position == null) {
          position = "cover_image";
        }
        // var dd= this.getBase64(files.path);
        // this.getBase64ImageFromURL(imageUrl).subscribe(base64data => {
        //   this.base64Image = 'data:image/jpg;base64,' + base64data;
        // });

        this.EditFile = data[i].path;
        this.getmultipleControl.push(
          this.bindValueArray(data[i].path, data[i].path, position, "OLD")
        );
      }
    }
    // this.InventoryForm["InventoryUploadData"] = [];
    // for (let files of data) {
    //   var position = files.position;
    //   if (position == null) {
    //     position = "cover_image";
    //   }
    //   // var dd= this.getBase64(files.path);
    //   // this.getBase64ImageFromURL(imageUrl).subscribe(base64data => {
    //   //   this.base64Image = 'data:image/jpg;base64,' + base64data;
    //   // });

    //   this.EditFile = files.path;
    //   this.getmultipleControl.push(
    //     this.bindValueArray(files.path, files.path, position, "OLD")
    //   );
    // }
  }

  getBase64Image(img: HTMLImageElement) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  BindControlArray(data) {
    this.InventoryForm["InventoryUploadData"] = [];
    // for (let file of data) {
    var position = "";
    let reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = (e: any) => {
      //this.urls.push(e.target.result);
      this.getmultipleControl.push(this.bindValueArray(data, e.target.result, position, "NEW"));
    };
    // }
  }

  get getmultipleControl() {

    return <FormArray>this.InventoryForm.get("InventoryUploadData");
  }

  BindValue() {
    this.fb.group({
      InventoryUploadData: this.fb.array([this.bindValueArray("", "", "", "")]),
    });
  }
  removeGroup(i: number) {
    this.confirmService
      .confirm({ message: ` Are you sure you want to remove this image ?` })
      .subscribe((data) => {
        if (data) {
          this.AllImageFile.splice(i, 1);
          const control = <FormArray>(
            this.InventoryForm.controls["InventoryUploadData"]);
          var filename = control.value[i].FileName;
          var ImageType = control.value[i].ImageType;
          if (this.InventoryID && ImageType !== "NEW") {
            filename = filename.replace("https://epcfs-sink.s3.amazonaws.com/", "");
            var newfilename = filename.split("?");
            const objData: InventoryImage = {} as InventoryImage;
            objData.inventory_id = parseInt(this.InventoryID);
            objData.file_path = newfilename[0];
            this.InvenService.RemoveInventoryById(objData).pipe(first()).subscribe((res) => {
              if (res instanceof HttpErrorResponse) {
                return;
              }
              if (res.success) {
                Swal.fire("Remove Image Successfully");
              } else {
                Swal.fire(res.data.msg, "Error");
              }
            },
              (error) => {
                Swal.fire(error.error.data.msg, "Error");
              }
            );
            control.removeAt(i);
          }
          else {
            control.removeAt(i);
            Swal.fire("Image Removed Successfully");
          }
          // if (this.InventoryID == null ||this.InventoryID == undefined ||this.InventoryID == "" ||this.InventoryID == " ") {
          //   control.removeAt(i);
          //   Swal.fire("Image Removed Successfully");
          // } 
          // else {
          //   filename = filename.replace("https://epcfs-sink.s3.amazonaws.com/","");
          //   var newfilename = filename.split("?");
          //   const objData: InventoryImage = {} as InventoryImage;
          //   objData.inventory_id = parseInt(this.InventoryID);
          //   objData.file_path = newfilename[0];
          //   this.InvenService.RemoveInventoryById(objData).pipe(first()).subscribe((res) => {
          //         if (res instanceof HttpErrorResponse) {
          //           return;
          //         }
          //         if (res.success) {
          //           Swal.fire("Remove Image Successfully");
          //         } else {
          //           Swal.fire(res.data.msg, "Error");
          //         }
          //       },
          //       (error) => {
          //         Swal.fire(error.error.data.msg, "Error");
          //       }
          //     );
          //   control.removeAt(i);
          // }
        }
      });
  }

  bindValueArray(FileName, Base64, Postion, ImageType) {
    return this.fb.group({
      FileImageSrc: [Base64],
      FileName: [FileName],
      ImagePositionType: [Postion || ""],
      ImageType: [ImageType],
    });
  }
  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  ApproveInventory(InventoryID: any) {
    const objData: ApproveData = {} as ApproveData;
    objData.id = InventoryID.toString();

  }

  labelToggal: any;
  updateFunc(e) {
    const checked = e.target.checked;
    if (checked == true) {
      this.CheckedLabel = "Dealer only";
      this.labelToggal = "My Stock"
    } else {
      this.CheckedLabel = "PAN India";
      this.labelToggal = "Regional Stock"
    }
  }


  checkDealer: any;
  backpage() {
    if (this.InventoryID) {
      if (this.checkDealer == "true") {
        this.router.navigate(['pages/InventoryList'], { queryParams: { index: 1 } });
      }
      else {
        this.router.navigate(['pages/InventoryList'], { queryParams: { index: 2 } });
      }
      // this.router.navigate(['pages/InventoryList']);
    }
    else {
        this.router.navigate(['pages/StockVehcile']);
    }
  }

  selectionChange(event, elementPosition) {

  }

  Filechange: any;
  url;
  url1;
  format;
  isfilechange: any = 'No';
  Edit: any;
  video_url_sDisplay: any = [];
  videoURL: any = [];

  onSelectVideoFile(event) {
    if (this.videoURL.length <= 4) {
      if (this.video_url_sDisplay.length < 1) {
        const file = event.target.files && event.target.files[0];
        var size = event.target.files[0].size;
        if (size > 5000000) {
          Swal.fire('Upload only 5 MB size files!')
          return
        }
        if (file.type.indexOf('video') != 0) {
          Swal.fire('Upload only video files!')
          return
        }
        this.isfilechange = 'Yes'
        this.Edit = 'No'
        if (file) {
          var reader = new FileReader();
          reader.readAsDataURL(file);
          if (file.type.indexOf('image') > -1) {
            this.format = 'image';
          } else if (file.type.indexOf('video') > -1) {
            this.format = 'video';
          }

          const file1 = event.target.files[0];
          if (this.videoURL.length >= 0) {
            for (var i = 0; i < this.videoURL.length; i++) {
              var data2 = this.videoURL[i];
              if (data2.name == file1.name) {
                Swal.fire('Video already Exists')
                return
              }
            }
          }

          //   if (this.video_url_sDisplay.length >= 0) {
          //     for (var i = 0; i < this.video_url_sDisplay.length; i++) {
          //       var data2 = this.video_url_sDisplay[i].mainurl;
          //       if (data2.name == file1.name) {
          //         Swal.fire('Video already Exists')
          //         return
          //       }
          //     }
          //   }
          // console.log(this.video_url_sDisplay)
          this.url = file1;
          this.videoURL.push(this.url)

          reader.onload = (event) => {
            // this.url = (<FileReader>event.target).result;
            this.url1 = (<FileReader>event.target).result;
            var json = {
              "Safurl": this.url1,
              "mainurl": this.url,
              "status": "new"
            }
            this.video_url_sDisplay.push(json)
          }
        }
      }
      else {
        Swal.fire({
          icon: 'info',
          title: 'Allowed only 1 video'
        })
      }
    }
    else {
      Swal.fire({
        icon: 'info',
        title: 'Max. allowed videos = 5 videos'
      })
    }
  }


  // RemoveVideo(id: any, number: number) {
  //   this.video_url_sDisplay.splice(number, 1);
  //   this.videoURL.splice(number, 1);
  // }


  removeVideo(i: number) {
    this.confirmService
      .confirm({ message: ` Are you sure you want to remove this vedio ?` })
      .subscribe((data) => {
        if (data) {
          const control = this.video_url_sDisplay;
          const control1 = this.videoURL;
          var filename = control[i].mainurl;
          var status = control[i].status

          // var ImageType = control.value[i].ImageType;
          if (this.InventoryID && status !== "new") {
            filename = filename.replace("https://epcfs-sink.s3.amazonaws.com/", "");
            var newfilename = filename.split("?");
            const objData: InventoryImage = {} as InventoryImage;
            objData.inventory_id = parseInt(this.InventoryID);
            objData.file_path = newfilename[0];

            this.InvenService.RemoveInventoryById(objData).pipe(first()).subscribe((res) => {
              if (res instanceof HttpErrorResponse) {
                return;
              }
              if (res.success) {
                Swal.fire("Remove Video Successfully");
              } else {
                Swal.fire(res.data.msg, "Error");
              }
            },
              (error) => {
                Swal.fire(error.error.data.msg, "Error");
              }
            );
            control.splice(i, 1);
            this.videoURL.splice(i, 1);
          }
          else {
            control.splice(i, 1);
            control1.splice(i, 1);
            Swal.fire("Video Removed Successfully");
          }
        }
      });
  }


  visibilityFunc(e) {
    if (e.value == 'dealer') {
      this.CheckedLabel = "Dealer only";
      this.labelToggal = "My Stock";
    }
    else if (e.value == 'regional') {
      this.CheckedLabel = "PAN India";
      this.labelToggal = "Regional Stock"
    }
  }


  onImageAndVideoNext(stepper: MatStepper) {
    if (this.InventoryForm.get("InventoryUploadData").value.length == 0) {
      Swal.fire("Please Upload Vehicle Image with their position type");
      return;
    }
    if (this.videoURL.length > 1) {
      Swal.fire("Please Upload only 1 video");
      return;
    }

    var images1 = this.InventoryForm.get('InventoryUploadData').value;
    var dataFront = images1.filter(book => book.ImagePositionType === 'front');
    var dataBack = images1.filter(book => book.ImagePositionType === 'back');
    var datalHs = images1.filter(book => book.ImagePositionType === 'lhs');
    var datarhs = images1.filter(book => book.ImagePositionType === 'rhs');
    var datalInside = images1.filter(book => book.ImagePositionType === 'inside_cabin');
    var datalspped = images1.filter(book => book.ImagePositionType === 'speedometer');
    var datachs = images1.filter(book => book.ImagePositionType === 'chassis');
    var datafrt = images1.filter(book => book.ImagePositionType === 'front_right_tyre');
    var dataflt = images1.filter(book => book.ImagePositionType === 'front_left_tyre');
    var datarrt = images1.filter(book => book.ImagePositionType === 'rear_right_tyre');
    var datarlt = images1.filter(book => book.ImagePositionType === 'rear_left_tyre');
    var dataty5 = images1.filter(book => book.ImagePositionType === 'tyre_5');
    var dataty6 = images1.filter(book => book.ImagePositionType === 'tyre_6');
    var dataty7 = images1.filter(book => book.ImagePositionType === 'tyre_7');
    var dataty8 = images1.filter(book => book.ImagePositionType === 'tyre_8');
    var dataty9 = images1.filter(book => book.ImagePositionType === 'tyre_9');
    var dataty10 = images1.filter(book => book.ImagePositionType === 'tyre_10');
    var dataty11 = images1.filter(book => book.ImagePositionType === 'tyre_11');
    var dataty12 = images1.filter(book => book.ImagePositionType === 'tyre_12');
    var dataspare = images1.filter(book => book.ImagePositionType === 'spare');
    if (dataFront.length > 1) {
      Swal.fire("Oops...", "Front Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataBack.length > 1) {
      Swal.fire("Oops...", "Back Position Is Selected Multiple Times", "error");
      return false
    }
    else if (datalHs.length > 1) {
      Swal.fire("Oops...", "LHS Position Is Selected Multiple Times", "error");
      return false
    }
    else if (datarhs.length > 1) {
      Swal.fire("Oops...", "RHS Position Is Selected Multiple Times", "error");
      return false
    }
    else if (datalInside.length > 1) {
      Swal.fire("Oops...", "Inside Cabin Position Is Selected Multiple Times", "error");
      return false
    }
    else if (datalspped.length > 1) {
      Swal.fire("Oops...", "Speedometer Position Is Selected Multiple Times", "error");
      return false
    }
    else if (datachs.length > 1) {
      Swal.fire("Oops...", "Chassis Position Is Selected Multiple Times", "error");
      return false
    }
    else if (datafrt.length > 1) {
      Swal.fire("Oops...", "Front Right Tyre Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataflt.length > 1) {
      Swal.fire("Oops...", "Front Left Tyre Position Is Selected Multiple Times", "error");
      return false
    }
    else if (datarrt.length > 1) {
      Swal.fire("Oops...", "Rear Right Tyre Position Is Selected Multiple Times", "error");
      return false
    }
    else if (datarlt.length > 1) {
      Swal.fire("Oops...", "Rear Left Tyre Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataty5.length > 1) {
      Swal.fire("Oops...", "Tyre 5 Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataty6.length > 1) {
      Swal.fire("Oops...", "Tyre 6 Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataty7.length > 1) {
      Swal.fire("Oops...", "Tyre 7 Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataty8.length > 1) {
      Swal.fire("Oops...", "Tyre 8 Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataty9.length > 1) {
      Swal.fire("Oops...", "Tyre 9 Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataty10.length > 1) {
      Swal.fire("Oops...", "Tyre 10 Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataty11.length > 1) {
      Swal.fire("Oops...", "Tyre 11 Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataty12.length > 1) {
      Swal.fire("Oops...", "Tyre 12 Position Is Selected Multiple Times", "error");
      return false
    }
    else if (dataspare.length > 1) {
      Swal.fire("Oops...", "Spare Tyre Position Is Selected Multiple Times", "error");
      return false
    }

    if (this.vehicle_certificate_type == "Non - Certified" && this.InventoryForm.get("InventoryUploadData").value.length > 0) {
      var fileAmount = this.InventoryForm.get("InventoryUploadData").value.length;
      for (let i = 0; i < fileAmount; i++) {
        var ImagePositionType = this.InventoryForm.get("InventoryUploadData").value[i].ImagePositionType;
        if (ImagePositionType == "") {
          Swal.fire("Please Select Image Position Type");
          return;
        }
        var images = this.InventoryForm.get('InventoryUploadData').value;
        var data2 = images.filter(book => book.ImagePositionType === 'cover_image');

        if (data2.length == 0) {
          Swal.fire("Oops...", 'If Vehicle Is Non-Certified, You Need To Upload At Least 1 Images With Cover Image Position Type', "error");
          return false
        }
        else if (data2.length > 1) {
          Swal.fire("Oops...", "Cover Image Position Is Selected Multiple Times", "error");
          return false
        }

      }

    }
    // else if (this.vehicle_certificate_type == "Certified" && this.InventoryForm.get("InventoryUploadData").value.length >= 21){
    else if (this.vehicle_certificate_type == "Certified" && this.InventoryForm.get("InventoryUploadData").value.length >= 13) {
      var fileAmount = this.InventoryForm.get("InventoryUploadData").value.length;
      for (let i = 0; i < fileAmount; i++) {
        //   var images = this.InventoryForm.get('InventoryUploadData').value;
        //   var data2 = images.filter(book => book.ImagePositionType === 'cover_image');
        // if (data2.length == 0) {
        //   Swal.fire("Oops...", 'Please Upload Vehicle Cover Image', "error");
        //   return false
        // }
        // else if(data2.length > 1)
        // {
        //   Swal.fire("Oops...", "Please select different position for each image", "error");
        //   return false
        // }
        var ImagePositionType = this.InventoryForm.get("InventoryUploadData").value[i].ImagePositionType;
        if (ImagePositionType == "") {
          Swal.fire("Please Select Image Position Type");
          return;
        }
      }
    }
    else {
      // Swal.fire("Please upload the image of the vehicle along with their Position type which should not be less than 21");
      Swal.fire("If Vehicle Is Certified, You Need To Upload At Least 13 Images With Their Position Type");
      return;
    }

    stepper.next();
  }
  updateDealerPrice(code,price){
    let payLoad = {
      "inventory_code":code,
      "dealer_suggestive_price":price
    }
    this.commonService.UpdatePrice(payLoad).subscribe(
      (res) => {
        if (res.success == true) {
        
        } else {
          Swal.fire("Oops...", res.msg, "error");
        }
      },
      (err) => {
        Swal.fire("Oops...", err.msg, "error");
      }
    );
  }
  getValueByKey(number,data) {
    return data[number] !== undefined ? data[number] : "";
  }
  getValidate(data){
    
    if (this.fromstock == true) {
      
   
    if (this.TechnicalDetailsArray.length>0) {
    const filteredData = this.TechnicalDetailsArray[3].content.filter(item => item.element_name == "Dealer Suggestive Price");
    const result = this.getValueByKey(filteredData[0].mapping_id,this.InventoryForm.value);
    if (!result || result === '') {
      // Show popup if the field is invalid or empty
      Swal.fire('Dealer Suggestive Price is required!');
      this.validatePrice=true
      return false;
    }
    this.stepper.next();
    this.validatePrice=false
    return true;
  }
}else{
  this.stepper.next();
  return true;
}
  }
  checkPrice(data){
    if (this.fromstock == true) {
      if (data.length > 0) {
        console.log(data.length);
        this.validatePrice=false;
      }
      else{
        this.validatePrice=true;
      }
      
    }
  }
}

export class AccodinData {
  header: string;
  content: any[];
}

export class InventoryImage {
  file_path: string;
  inventory_id: number;
}